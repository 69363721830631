import React from 'react'
import ContactForm, { ContactFormRight } from '../components/ContactForm';
import { useTitle } from '../useTitle';
import SocialContacts from '../components/SocialContacts';
import ChooseusImg from "../assets/WhyChooseUsL1-01.svg";
import teamImg from '../assets/bgElement/TeamworkBg-01.svg'

const ContactUs = () => {
  useTitle("Contact");
  return (
    <section className='containerbg'>
      <SocialContacts />



      <div className="containerbg relative rounded-b-[30px] border-b-4 border-teal-700 md:rounded-b-[100px] w-full h-[400px] flex flex-col items-center pb-5 justify-end m-auto">
        <h2 className='fontCabinet text-center text-xl 3xl:text-4xl font-bold text-teal-400 uppercase z-30'>We are your software development team in the cloud.
        </h2>
        <p className=' text-sm sm:text-xl text-white text-center px-5 pt-3 z-20'>
          We are a team of passionate designers and developers who love building great digital products and experiences.
        </p>
        <img src={ChooseusImg} alt="" className=' absolute bottom-0 w-[400px] opacity-[0.5] z-10' />
        <img className='w-[350px] hidden sm:block object-cover absolute bottom-0 right-0 opacity-[0.4]' src={teamImg} alt="" />
        <img className='w-[350px] hidden sm:block object-cover absolute bottom-0 left-0 opacity-[0.4]' src={teamImg} alt="" />
      </div>



      <div className=" relative px-3 3xl:px-0 3xl:w-[1536px] pt-[40px] 3xl:pt-[80px] flex flex-col justify-center m-auto">

        {/* <div className="flex flex-col gap-10">
          <div className="w-full lg:flex-[30%] containerbg shadow-md shadow-black rounded-xl p-5 py-[100px] text-gray-300">
            <div className="relative w-full">
              <div className=" text-center w-full">
                <h3 className=' fontCabinet text-2xl md:text-[3xl] 2xl:text-4xl font-bold'>Build products together with your dedicated team.
                </h3>
                <p className=' text-lg lg:text-xl pt-3'>We do everything from research, planning, execution, deployment and quality assurance.</p>
                <h2 className='pt-5 text-xl font-bold'>LET’S WORK TOGETHER!</h2>
              </div>
            </div>
          </div>
        </div> */}


        <div className="containerbg shadow-md shadow-black rounded-xl mt-[10px] 3xl:mt-[50px] py-10">
          <div className=" p-2 relative xl:w-[1250px] flex flex-col m-auto">
            <h2 className=" text-center">
              <span className='text-5xl font-bold text-teal-300 '>Build products together with your dedicated team.</span>
              <p className='text-gray-300 text-xl mt-5'>We do everything from research, planning, execution, deployment and quality assurance.
                <br />
                We would love to hear from you! We will get back to you within 24-48 hours.</p>
            </h2>
            <div className=" pt-[60px] relative grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-1 xl:gap-6">
              <ContactForm />
              <ContactFormRight />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs