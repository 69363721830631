import React from 'react'
import { Link } from 'react-router-dom'
import { CgMail } from "react-icons/cg";
import Subscribe from '../components/careers/Subscribe';
import resume_apply from '../assets/careers/resume_apply.svg'
import man from "../assets/HiringMan-01.png"
import ContactPopup from '../components/careers/ContactPopup';
import WhyYouShouldJoinWithUs from '../components/careers/WhyYouShouldJoinWithUs';
import OurCoreValues from '../components/careers/OurCoreValues';
import SocialContacts from '../components/SocialContacts';

const Careers = () => {

    const jobListings = [
        {
            id: 1,
            title: "Software Sales and Marketing Specialist",
            experience: "Minimum 2+ years",
            deadline: "",
            
        },
        {
            id: 2,
            title: "Junior Frontend Developer",
            experience: "Minimum 1+ years",
            deadline: "",
            

        }
        // Add more job objects as needed
    ];


    return (
        <section className="containerbg">
             <SocialContacts/>
            <div className="placeholder:h-full md:pt-40 lg:pt-44 pt-32 md:pb-36 pb-24 container-fluid relative">
                <ContactPopup />
                {/* ------------------------------------ */}
                <div className="relative overflow-hidden">
                    <div className="absolute -top-4 lg:-top-10 sm:right-0 right-[-9px]">
                        <img src={man} alt="Careers" className="w-[24vw] h-[14.86vw] lg:w-[18.5vw] lg:[h-11.47vw]" />
                    </div>
                    <h1 className="text-primary text-teal-400  text-5xl font-bold text-center py-2 sm:py-4 md:pt-8 md:pb-14"> Careers </h1>
                    <div className="py-10 flex justify-center items-center px-3 sm:px-0">
                        <div className="containerbg shadow-xl border-[0.1px] border-teal-400 rounded-xl cursor-pointer flex-shrink-0 max-w-full sm:max-w-[80vw] md:max-w-[70vw] lg:max-w-[80vw] xl:max-w-[70vw] 2xl:max-w-[60vw] 3xl:max-w-[820px] px-2 py-6 md:px-6 xl:px-12 flex flex-col space-y-1 sm:space-y-2 lg:space-y-3 xl:space-y-4">
                            <Link to='#' target="_blank" className="flex flex-col space-y-4">
                                <p className="text-center text-lg md:text-xl lg:text-2xl xl:leading-[62px] text font-bold text-gray-300"> No Opening for you, Fill up the </p>
                                <h2 className="text-center text-2xl md:text-3xl lg:text-4xl xl:leading-[62px] text font-bold text-teal-400 text-primary"> Potential candidate list form </h2>
                                <div className="text-gray-300 text-center text-xs sm:text-sm md:text-base font-medium sm:px-9"> This is a Potential Candidate form List. These informations will be used (will help us ) to find out the <b >best possible potential candidate</b> for <b >future hiring.</b> You’re most welcome to be a part of this <b >Program.</b> We wish you best of luck for your <b >future endeavours.</b></div>
                                <div className="text-xs sm:text-base md:text-lg lg:text-xl text-center text-gray-300 font-bold relative flex space-x-2 items-center justify-center">
                                    <div>Feel free to</div>
                                    <div className="text-teal-400 text-lg sm:text-xl md:text-2xl lg:text-3xl"> Apply </div>
                                    <div>
                                        <svg className="svg-inline--fa fa-chevron-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                            <path className="" fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                                        </svg>
                                    </div>
                                </div>
                            </Link>

                            <div className="flex md:flex-row flex-col md:space-x-[30px] md:space-y-0 space-y-3 w-full md:justify-center items-center !mt-6" >
                                <div className="flex space-x-3 items-center" >
                                    < CgMail className='text-[25px] text-teal-400' />
                                    <p className="text-base font-normal block text-gray-300" > career@softwarechamber.com </p>
                                </div>
                                <div className="flex space-x-3 items-center" >
                                    < CgMail className='text-[25px] text-teal-400' />
                                    <p className="text-base font-normal block text-gray-300" > career-softwarechamber@dev.com </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="text-[#F0F0F0] text-center md:pt-[60px] 3xl:pt-[80px] px-3 sm:px-0" >
                    <p className="text-xl sm:text-2xl font-semibold" >Are You Ready to Join Our Team</p>
                    <h2 className=" text-xl sm:text-2xl md:text-5xl font-bold pt-1 sm:pt-4" > Open Positions,<span className=" text-teal-400" > You Want To Be Part Of Us?</span></h2>
                    <p className="text-lg pt-1 sm:pt-4" > We have the following positions for you right now </p>
                </div>


                {/* ---------------------job------------------- */}
                <div className="mt-[100px]">
                    <h2 className=" text-teal-400 text-xl md:text-[45px] font-bold text-center pb-5">Developer</h2>

                    <div className="w-full px-3 sm:px-5 md:px-0 md:w-[768px] lg:w-[80%] 3xl:w-[1536px] flex flex-col justify-center m-auto">
                        {
                            jobListings?.map((jobListing, index) => (
                                <div key={index} className="containerbg border-[0.2px] border-teal-400 shadow-xl rounded-md px-3.5 md:px-5 lg:px-10 xl:px-14 py-4 my-8 flex flex-row items-center justify-between">
                                    <div className="flex flex-col space-y-1 md:space-y-6 pr-4">
                                        <div className="text-gray-300 text-base md:text-xl lg:text-[32px] font-semibold"> {jobListing.title}</div>
                                        <div className="text-gray-300 flex flex-col space-y-1 text-sm md:text-base">
                                            <div ><span className="text-primary text-teal-400">Experience:</span> {jobListing.experience}</div>
                                            <div ><span className="text-primary text-teal-400">Deadline:</span> {jobListing.deadline}</div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <Link to={`/career/${jobListing.id}`} className="whitespace-nowrap w-[120px] h-9 lg:w-40 lg:h-10 xl:w-44 xl:h-12 bg-teal-500 px-5 py-3 font-bold text-gray-200 text-xs lg:text-base rounded-sm flex gap-3 items-center justify-center">
                                            <span >Apply Now</span>
                                            <svg className="svg-inline--fa fa-chevron-right w-[10px]" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                <path className="" fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        }


                    </div>
                </div>

                <div className="w-full px-3 sm:px-5 md:px-0 md:w-[768px] lg:w-[80%] 3xl:w-[1536px] flex flex-col justify-center m-auto">
                    <div className="text-center mt-20 md:mt-[150px]">
                        <h2 className=" text-teal-400 text-3xl sm:text-5.5xl leading-[46px] md:leading-[72px] font-semibold text-primary mb-5 lg:mb-6"> Subscribe to us for all future job post notifications. </h2>
                        <Subscribe />
                    </div>
                </div>

                <div className="w-full px-3 sm:px-5 md:px-0 md:w-[768px] lg:w-[80%] 3xl:w-[1536px] flex flex-col justify-center m-auto">
                    <div className="text-center mt-20 md:mt-[150px]">
                        <h2 className=" text-teal-400 text-4xl sm:text-5.5xl leading-[46px] sm:leading-[72px] font-bold mb-5 lg:mb-6"> Why you should join with us? </h2>
                        <p className="text-lg text-gray-300  lg:px-10 mb-12 lg:mb-[70px]"> Talented software engineers on board, we craft compelling web, and mobile applications for our clients. Since our inception, we have partnered with numerous companies and delivered operational gains to startup, emerging, and established organizations in the World </p>
                    </div>

                    <div className="">
                        <WhyYouShouldJoinWithUs />
                    </div>
                </div>


                {/* ---------------------------------------------------------------Our Core Values------------------------------------------------------ */}
                <div className="w-full px-3 sm:px-5 md:px-0 md:w-[768px] lg:w-[80%] 3xl:w-[1536px] flex flex-col justify-center m-auto">

                    <div className="text-center">
                        <h2 className=" text-teal-400 text-4xl sm:text-5.5xl leading-[46px] sm:leading-[72px] font-bold mb-5 lg:mb-6"> Our Core Values </h2>
                        <p className="text-lg text-gray-300  lg:px-10 mb-12 lg:mb-[70px]"> As well as a team of consultants, analysts, software architects, designers and engineers who meticulously understand and value clients' vision to passionately deliver transformation through technology globally. Our goal is to help clients make full use of technology that can heighten brand awareness and lead to better business. </p>
                    </div>
                    <div className="">
                        <OurCoreValues />
                    </div>
                    <div className="containerbg border border-teal-400 mt-[140px] md:px-10 md:py-10 w-full max-h-[400px] rounded-3xl flex-col px-3.5 mx-auto flex lg:flex-row lg:space-x-4 justify-around items-center text-center py-3" >
                        <div >
                            <img
                                className="shadow-lg w-[18vw] h-[18vw] max-w-[150px] max-h-[150px] lg:w-[21.01vw] lg:h-[21.01vw] lg:max-w-[225px] lg:max-h-[225px] mx-auto mb-2 md:mb-0"
                                src={resume_apply}
                                alt="resume-apply"
                            />
                        </div>
                        <div className="md:py-5 text-center md:text-left">
                            <div>
                                <h2 className="pb-1 pl-5 text-base md:text-3xl text-teal-400 text-center lg:text-left">Even if above opened positions do not match with your profile, we still encourage you to apply.</h2>
                                <p className=" text-gray-300 pb-1 pl-5 text-base md:text-3xl text-primary text-center lg:text-left">
                                    Feel free to <Link to="" target="_blank" className="font-bold"> Apply </Link>
                                </p>
                            </div>
                        </div>
                        <div className="mb-5 lg:mb-0 py-5" data-v-b1a38706="">
                            <Link
                                to=""
                                rel="noopener noreferrer"
                                target="_blank"
                                className="w-32 h-40 lg:w-40 lg:h-10 xl:w-48 xl:h-12 bg-teal-400 text-[#1A1139] px-5 py-3 font-bold text-base rounded-sm whitespace-nowrap"
                                data-v-b1a38706=""
                            >
                                Apply Now
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Careers