import React, { useState } from 'react'
import blogimage from '../assets/blog/blog-01.jpg'
import { Link } from 'react-router-dom'
import BlogLeft from '../components/BlogLeft';

import { LiaCommentsSolid } from "react-icons/lia";
import { FaRegClock } from "react-icons/fa";
import { BsTags } from "react-icons/bs";
import { useTitle } from '../useTitle';

const BlogList = () => {

  useTitle('Blog List');

  const blogPosts = [
    {
      id: 1,
      title: 'Doccure – Making your clinic painless visit?',
      author: 'Dr. Ruby Perrin',
      date: '4 Dec 2019',
      comments: 12,
      tags: 'Health Tips',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      image: blogimage
    },
    {
      id: 2,
      title: 'Doccure – Making your clinic painless visit?',
      author: 'Dr. Ruby Perrin',
      date: '4 Dec 2019',
      comments: 12,
      tags: 'Health Tips',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      image: blogimage
    },
    {
      id: 3,
      title: 'Doccure – Making your clinic painless visit?',
      author: 'Dr. Ruby Perrin',
      date: '4 Dec 2019',
      comments: 12,
      tags: 'Health Tips',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      image: blogimage
    },
    {
      id: 4,
      title: 'Doccure – Making your clinic painless visit?',
      author: 'Dr. Ruby Perrin',
      date: '4 Dec 2019',
      comments: 12,
      tags: 'Health Tips',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      image: blogimage
    },
    {
      id: 5,
      title: 'Doccure – Making your clinic painless visit?',
      author: 'Dr. Ruby Perrin',
      date: '4 Dec 2019',
      comments: 12,
      tags: 'Health Tips',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      image: blogimage
    },
    {
      id: 6,
      title: 'Doccure – Making your clinic painless visit?',
      author: 'Dr. Ruby Perrin',
      date: '4 Dec 2019',
      comments: 12,
      tags: 'Health Tips',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      image: blogimage
    },
    {
      id: 7,
      title: 'Doccure – Making your clinic painless visit?',
      author: 'Dr. Ruby Perrin',
      date: '4 Dec 2019',
      comments: 12,
      tags: 'Health Tips',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      image: blogimage
    },
    {
      id: 8,
      title: 'Doccure – Making your clinic painless visit?',
      author: 'Dr. Ruby Perrin',
      date: '4 Dec 2019',
      comments: 12,
      tags: 'Health Tips',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      image: blogimage
    },
  ];

  const latestPosts = [
   
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  // Calculate the posts to display based on current page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(blogPosts.length / postsPerPage);

  return (
    <section>
      <div className="pt-[150px] containerbg">
        <div className="w-full px-3 md:px-0 md:w-[768px] lg:w-[80%] 3xl:w-[1336px] flex flex-col justify-center m-auto">
          <div className="content">
            <div className="container">
              <div className="row flex flex-col md:flex-row gap-5">
                <div className="flex flex-col gap-5 md:flex-[60%] 3xl:flex-[70%]">
                  {currentPosts.map((post) => (
                    <div key={post.id} className="blog">
                      <div className="blog-image">
                        <Link to={`/Blog/${post.id}`}>
                          <img className="img-fluid" src={post.image} alt="Post Image" />
                        </Link>
                      </div>
                      <h3 className="blog-title"><Link to={`/Blog/${post.id}`}>{post.title}</Link></h3>
                      <div className="blog-info clearfix">
                        <div className="post-left">
                          <ul>
                            <li>
                              <div className="post-author">
                                <Link to={`/Blog/${post.id}`}>
                                  <img src={post.image} alt="Post Author" />
                                  <span>{post.author}</span>
                                </Link>
                              </div>
                            </li>
                            <li className='flex items-center gap-1'><LiaCommentsSolid />{post.date}</li>
                            <li className='flex items-center gap-1'><FaRegClock />{post.comments} Comments</li>
                            <li className='flex items-center gap-1'><BsTags />{post.tags}</li>
                          </ul>
                        </div>
                      </div>
                      <div className="blog-content">
                        <p>{post.content}</p>
                        <Link to={`/Blog/${post.id}`} className="read-more">Read More</Link>
                      </div>
                    </div>
                  ))}
                  <nav aria-label="Page navigation example">
                    <ul className="flex items-center m-auto justify-center -space-x-px h-10 text-base">
                      <li>
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          disabled={currentPage === 1}
                          className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          <span className="sr-only">Previous</span>
                          <svg
                            className="w-3 h-3 rtl:rotate-180"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 1 1 5l4 4"
                            />
                          </svg>
                        </button>
                      </li>
                      {[...Array(totalPages)].map((_, pageIndex) => (
                        <li key={pageIndex}>
                          <button
                            onClick={() => paginate(pageIndex + 1)}
                            className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${currentPage === pageIndex + 1 ? 'bg-gray-300' : ''
                              }`}
                          >
                            {pageIndex + 1}
                          </button>
                        </li>
                      ))}
                      <li>
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          disabled={currentPage === totalPages}
                          className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          <span className="sr-only">Next</span>
                          <svg
                            className="w-3 h-3 rtl:rotate-180"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                  <br />
                </div>
                <BlogLeft latestPosts={latestPosts} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogList;
