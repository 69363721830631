import React from 'react';
// import { Helmet } from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const SEO = ({ 
  title, 
  description, 
  keywords, 
  author, 
  url, 
  image,
  additionalMeta = [] 
}) => {
  return (
    <HelmetProvider>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="theme-color" content="#1f6e6c" />
      {additionalMeta.map((metaTag, index) => (
        <meta key={index} {...metaTag} />
      ))}
    </Helmet>
    </HelmetProvider>

  );
};


export default SEO;
