import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import django from '../assets/stackPowers/django2x-logo.png';
import djangorestframework from '../assets/stackPowers/djangorestframework2x-logo.png';
import next from '../assets/stackPowers/next2x-logo.png';
import python from '../assets/stackPowers/python2x-logo.png';
import react from '../assets/stackPowers/react2x-logo.png';
import postgresql from '../assets/stackPowers/postgresql2x-logo.png';
import redis from '../assets/stackPowers/redis2x-logo.png';
import aws from '../assets/stackPowers/aws2x-logo.png';
import digitalOcean from '../assets/stackPowers/digitalocean-2.svg';

export default function StackPowers() {
  const imageDataList = [
    { id: 1, name: 'python', image: python },
    { id: 2, name: 'django', image: django },
    { id: 3, name: 'django-rest-framework', image: djangorestframework },
    { id: 4, name: 'react.js', image: react },
    { id: 5, name: 'next.js', image: next },
    { id: 6, name: 'postgresql', image: postgresql },
    { id: 7, name: 'redis', image: redis },
    { id: 8, name: 'digitalOcean', image: digitalOcean },
    { id: 9, name: 'aws', image: aws },
  ];

  const carouselProperties = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    // variableWidth: true,
    // pauseOnHover: false,
    arrows: false,


    responsive: [
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };



  const secondCarouselProperties = {
    ...carouselProperties,
    rtl: true, // Right-to-left direction for continuous scroll effect
  };



  return (
    <section className="relative pt-3 mt-0 w-full h-full">
      <div className="w-full h-full flex flex-col gap-5 lg:gap-8 xl:gap-[60px] justify-center items-start overflow-hidden z-[99999] py-5">
        <div className="w-full">
          <div className="mx-auto max-w-7xl pt-12 xl:py-12 px-0 sm:px-6 lg:py-16 lg:px-8">
            <div className="lg:grid gap-5 lg:grid-cols-2 lg:items-center lg:gap-8">
              <div>
                <h2 className="text-2xl sm:text-4xl 3xl:text-5xl 3xl:leading-[55px] font-bold tracking-tight text-teal-400">
                  Our stack powers some of the world's most beloved companies
                </h2>
                <p className="mt-3 pb-10 lg:pb-0 max-w-3xl text-md sm:text-lg text-gray-300">
                  We have carefully selected a few of the most beloved technology stacks that power everything from fast-growing startups to billion-dollar companies.
                </p>
              </div>

              <div className="w-full">
                <div className="max-w-3x">
                  <Slider {...carouselProperties}>
                    {imageDataList.map(imageData => (
                      <div key={imageData.id} className="cardbg flex justify-center py-8 px-8 w-auto ">
                        <img className="h-12 w-full" src={imageData.image} alt={imageData.name} title={imageData.name} />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className="max-w-3xl">
                  <Slider {...secondCarouselProperties}>
                    {imageDataList.map(imageData => (
                      <div key={imageData.id} className="cardbg mx-5 flex justify-center py-8 px-8 w-auto ">
                        <img className="h-12 w-full" src={imageData.image} alt={imageData.name} title={imageData.name} />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
