import React from 'react'
import abstractCircle from '../assets/bgElement/abstract circle-01.svg'
import Testimonial from './Testimonial';
import ContactForm, { ContactFormRight } from './ContactForm';

const Contactus = () => {
  return (
    <section className='containerbg pb-[80px]'>
      <div className='containerbg relative pb-[50px] rounded-b-[100px] 3xl:rounded-b-[500px] border-b-[30px] border-b-[#1f6e6c] shadow-2xl shadow-[#1f6e6c] w-full h-full rounded-t-[30px] 3xl:rounded-t-[200px] overflow-hidden'>
        <div className=" w-[400px] h-[400px] absolute top-[80px] -left-[140px] opacity-[0.2]">
          <img className='w-full h-full object-cover' src={abstractCircle} alt="" />
        </div>
        <div className=" 3xl:pb-[250px] xl:mb-[0px] sm:px-3 w-full lg:w-[95%] xl:w-[80%] 3xl:[1536px] flex-col items-stretch justify-center m-auto z-[4444]">
          <Testimonial />
          <div className=" flex items-center justify-center md:pb-10 xl:pb-0">
            <div className=" w-[1300px] 3xl:mb-10 3xl:mt-[60px] 3xl:h-[700px] mx-auto pt-6 rounded-t-lg">
              <div className=" relative pb-10 xl:pb-[50px]">
                <p className='text-gray-300 text-center py-1 sm:py-3 text-sm font-medium'>Any Question?</p>
                <h1 className='fontCabinet text-center pb-5 text-2xl sm:text-3xl 3xl:text-5xl font-bold text-teal-400'>Contact Us</h1>
                <p className=' text-center text-gray-300 font-medium text-sm sm:text-lg 3xl:text-xl p-3 3xl:p-0'>It is our joy to have the opportunity to collaborate. <br />
                  We would love to hear from you! We will get back to you within 24-48 hours.</p>
              </div>
              <div className="bg-[#1d3645] m-3 shadow-sm shadow-black rounded-md py-10 relative grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-1 xl:gap-6">
                {/* Contact Form */}
                <ContactForm />
                <ContactFormRight />

              </div>
            </div>
          </div>
        </div>

        {/* <div className=" z-50 absolute bottom-[5%] sm:bottom-[10%] md:bottom-[20%] lg:bottom-[20%] md:right-[0%] xl:right-[10%] lg:right-[5%] 3xl:top-[56%] right-0 3xl:right-[14%]">
          <div className="max-w-[400px] flex flex-col gap-0 3xl:gap-8 text-gray-300">
            <div className=" cursor-pointer p-3 rounded-full flex items-center justify-center m-auto  w-[100px] h-[100px]  ">
              <Link to='' target="_blank" title="click">
                <FaFacebookMessenger  className='text-[50px] hover:text-[60px] transition ease-in-out 3xl:text-[80px] 3xl:hover:text-[90px] text-[#1f6e6c]'/>
              </Link>
            </div>

            <div className="relative group cursor-pointer p-3 rounded-full flex items-center justify-center m-auto  w-[100px] h-[100px]">
              <Link to='tel:01832-344599' target="_blank" title='01832-344599'>
                <LiaPhoneVolumeSolid className='text-[50px] hover:text-[60px] transition ease-in-out 3xl:text-[80px] 3xl:hover:text-[90px] text-[#1f6e6c]'/>
              </Link>
              <span className='absolute hidden top-0 w-full h-fit py-2 font-bold text-gray-300
              group-hover:block group-hover:transition-all
              '>01832344599</span>
            </div>

            <div className=" relative group cursor-pointer p-3 rounded-full flex items-center justify-center m-auto  w-[100px] h-[100px]">
              <Link to='mailto:softwarechamber@gmail.com' target="_blank" title='softwarechamber@gmail.com'>
                <CgMail className='text-[50px] hover:text-[60px] transition ease-in-out 3xl:text-[80px] 3xl:hover:text-[90px] text-[#1f6e6c]'/>
              </Link>

              <span className='absolute hidden top-0 w-full h-fit py-2 font-bold text-gray-300
              group-hover:block group-hover:transition-all
              '>softwarechamber@gmail.com</span>
            </div>

            <div className=" cursor-pointer p-3 rounded-full flex items-center justify-center m-auto  w-[100px] h-[100px]">
              <Link to='https://web.whatsapp.com/' target="_blank" title='Click'>
                <IoLogoWhatsapp className='text-[50px] hover:text-[60px] transition ease-in-out 3xl:text-[80px] 3xl:hover:text-[90px] text-[#1f6e6c]' />
              </Link>
            </div>

          </div>
        </div> */}
        <div className=" hidden 3xl:block w-[400px] h-[400px] absolute bottom-0 right-0 opacity-[0.2]">
          <img className='w-full h-full object-cover rotate-180' src={abstractCircle} alt="" />
        </div>
      </div>
    </section>
  )
}

export default Contactus