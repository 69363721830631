import React from 'react'
import Accordion from './Accordion';
import abstractCircle from '../../assets/bgElement/abstract circle-01.svg'


const Faqs = () => {
    const faqs = [
        {
            "id": 1,
            "question": "What software development services does your company offer?",
            "answer": "Software chamber is your one-stop IT partner offering mobile apps, custom software development solutions, web applications, AI development, blockchain solutions, and more. We also offer dedicated resource hiring."
        },
        {
            "id": 2,
            "question": "What industries do you specialize in?",
            "answer": "Our software solutions company is well-versed in a wide range of industries including healthcare, finance, eCommerce, education, logistics, sports & gaming, real estate, and many other industries. We have the expertise and resources that meet the demands of every sector."
        },
        {
            "id": 3,
            "question": "How long does it typically take to develop a mobile app or software?",
            "answer": "The length of the development process varies with the complexity and size of the project, but we prioritize efficiency without sacrificing quality. Generally, mobile app development takes a couple of months, while software development can take 6 months to a year depending on the scale of the project."
        },
        {
            "id": 4,
            "question": "Do you provide ongoing software support and maintenance after the project is completed?",
            "answer": "Yes, we offer ongoing support and maintenance to ensure your product continues to function seamlessly after completion. We can also help you with implementing new features in your software and integrating third-party services."
        },
        {
            "id": 5,
            "question": "Is my project idea and information kept confidential?",
            "answer": "Confidentiality and privacy are of paramount importance to us. For complete data protection, we ensure that our clients and employees sign strict non-disclosure agreements. Whether it’s protecting ideas and codes or ensuring code ownership, we’re committed to maintaining data security and confidentiality."
        },
        {
            "id": 6,
            "question": "What sets your company apart from the competition?",
            "answer": "In contrast to traditional software development companies, we have built, scaled, and maintained our own successful SaaS and FinTech products. This provides us with a comprehensive understanding of the full product life cycle, enabling us to offer you unparalleled expertise."
        }
    ];

    return (
        <section className="containerbg pt-10 textColor pb-[20px] 3xl:pb-0">

            <div className='containerbg relative rounded-b-[100px] 3xl:rounded-b-[300px] border-b-[30px] border-b-[#1f6e6c] shadow-2xl shadow-[#1f6e6c] w-full rounded-t-[30px] 3xl:rounded-t-[200px] overflow-hidden'>
                <div className=" w-[400px] h-[400px] absolute top-[80px] -left-[140px] opacity-[0.2]">
                    <img className='w-full h-full object-cover' src={abstractCircle} alt="" />
                </div>
                <div className="rounded-md px-3 py-10 w-full lg:w-[80%] flex-col items-stretch justify-center m-auto z-[100]">

                    <div className=" relative w-full">
                        <h1 className='fontCabinet text-center text-2xl 3xl:text-4xl font-bold text-teal-400 uppercase pb-3'>FAQS</h1>
                    </div>

                    {/* <h1 className="fontCabinet text-center text-2xl 3xl:text-4xl font-bold text-teal-400 p-3 uppercase">FAQS</h1> */}
                    <p className='text-center text-sm sm:text-lg font-medium text-gray-300'>Frequently asked questions on software development</p>

                    <div className=" w-full 3xl:w-[1000px] flex m-auto justify-center gap-5">
                        {/* <div className=" flex-1 p-10">
                            <img className='w-fit h-fit object-cover' src={faqImage} alt="" />
                        </div> */}
                        <div className="">
                            <div className="my-10" id="accordion-flush" data-accordion="collapse" data-active-classes="bg-white dark:bg-gray-900 text-gray-900" data-inactive-classes="text-gray-500">
                                {faqs.map((faq) => (
                                    <Accordion key={faq.id} faq={faq} />
                                ))}
                            </div>
                        </div>
                    </div>

                </div>

                <div className=" w-[400px] h-[400px] absolute bottom-0 right-0 opacity-[0.2]">
                    <img className='w-full h-full object-cover rotate-180' src={abstractCircle} alt="" />
                </div>
            </div>


        </section>
    )
}

export default Faqs