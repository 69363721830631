import React from 'react'
import { useTitle } from '../useTitle';
import SocialContacts from '../components/SocialContacts';
import { ContactFormRight } from '../components/ContactForm';
import ChooseusImg from "../assets/WhyChooseUsL1-01.svg";
import teamImg from '../assets/bgElement/TeamworkBg-01.svg'


const BuildProduct = () => {
    useTitle("Contact");
    return (
        <section className='containerbg'>
            <SocialContacts />

            {/* <div className="shadow-xl containerbg relative rounded-b-[30px] sm:rounded-b-[100px] w-full h-[400px] flex flex-col items-center justify-center m-auto">
                <div className="text-gray-300 p-5 3xl:p-0 flex flex-col items-center text-center 3xl:mt-[80px]">
                    <h2 className='fontCabinet text-xl 3xl:text-5xl font-bold uppercase'></h2>
                    <p className='w-full flex pt-5 text-sm 3xl:text-xl m-auto items-center justify-center'>
                        We are a team of passionate designers and developers who love building great digital products and experiences.
                    </p>
                </div>
            </div> */}



            <div className="containerbg relative rounded-b-[30px] border-b-4 border-teal-700 md:rounded-b-[100px] w-full h-[400px] flex flex-col items-center justify-center m-auto">
                <h2 className='fontCabinet text-center text-2xl 3xl:text-4xl font-bold text-white uppercase z-30'>We are your software development team in the cloud.</h2>
               <p className='text-white text-sm 3xl:text-xl pt-2 px-5 text-center z-30'>We are a team of passionate designers and developers who love building great digital products and experiences.</p>
                <img src={ChooseusImg} alt="" className=' absolute bottom-0 w-[400px] opacity-[0.5] z-10' />
                <img className='w-[350px] hidden sm:block object-cover absolute bottom-0 right-0 opacity-[0.4]' src={teamImg} alt="" />
                <img className='w-[350px] hidden sm:block object-cover absolute bottom-0 left-0 opacity-[0.4]' src={teamImg} alt="" />
            </div>




            <div className=" relative px-3 3xl:px-0 3xl:w-[1536px] pt-[40px] 3xl:pt-[80px] flex flex-col justify-center m-auto">

                <div className="containerbg shadow-md shadow-black rounded-xl mt-[150px] py-10">
                    <div className=" p-2 relative xl:w-[1250px] flex flex-col m-auto">
                        <h2 className=" text-center">
                            <span className='text-5xl font-bold text-teal-300 '>Build products together with your dedicated team.
                            </span>
                            <p className='text-gray-300 text-xl mt-5'>It is our joy to have the opportunity to collaborate.
                                <br />
                                We would love to hear from you! We will get back to you within 24-48 hours.</p>
                        </h2>
                        <div className=" pt-[60px] relative grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-1 xl:gap-6">


                            {/* -----------------form-------------------- */}
                            <div className='p-2 3xl:pl-10'>
                                <form className='flex flex-col gap-10 p-0 py-0 text-white'>
                                    <div className="">
                                        <label htmlFor="Name" className="block text-gray-300 font-medium mb-2">Enter Your Name*</label>
                                        <div className=" bg-teal-800 px-3 py-2 rounded-lg">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder='Enter Your Name*'
                                                className=" z-[-10] w-full placeholder:text-gray-400  py-2 bg-transparent focus:outline-none"
                                            />
                                        </div>
                                    </div>

                                    <div className="">
                                        <label htmlFor="Email" className="block text-gray-300 font-medium mb-2">Enter Your Email*</label>
                                        <div className="bg-teal-800 px-3 py-2 rounded-lg">
                                            <input
                                                type="email"
                                                id="Email"
                                                name="Email"
                                                placeholder='Enter Your Email*'
                                                className=" z-[-10] w-full placeholder:text-gray-400 py-2 bg-transparent focus:outline-none"
                                            />
                                        </div>
                                    </div>

                                    <div className="">
                                        <label htmlFor="phone" className="block text-gray-300 font-medium mb-2">Enter Your Phone Number(Optional)</label>
                                        <div className="bg-teal-800 px-3 py-2 rounded-lg">
                                            <input
                                                type="text"
                                                id="phone"
                                                name="phone"
                                                placeholder='Enter Your Phone Number*'
                                                className=" z-[-10] w-full placeholder:text-gray-400 py-2 bg-transparent focus:outline-none"
                                            />
                                        </div>
                                    </div>

                                    <div className="">
                                        <label htmlFor="companyName" className="block text-gray-300 font-medium mb-2">Company Name(Optional)</label>
                                        <div className=" bg-teal-800 px-3 py-2 rounded-lg">
                                            <input
                                                type="text"
                                                id="companyName"
                                                name="companyName"
                                                placeholder='Enter Your Company Name*'
                                                className=" z-[-10] w-full placeholder:text-gray-400  py-2 bg-transparent focus:outline-none"
                                            />
                                        </div>
                                    </div>

                                    <div className="">
                                        <label htmlFor="companyName" className="block text-gray-300 font-medium mb-2">Deep Details About Your Query (Optional)
                                        </label>
                                        <div className="bg-teal-800 px-3 py-2 rounded-lg">
                                            <textarea
                                                id="message"
                                                name="message"
                                                rows="3"
                                                placeholder='Tell us more about your query*'
                                                className=" resize-none z-[-10] w-full placeholder:text-gray-400  py-2 bg-transparent focus:outline-none"
                                            ></textarea>
                                        </div>
                                    </div>


                                    <div class="flex flex-col gap-4">
                                        <label class="text-gray-300 font-medium text-base">Services You Need
                                            <span class="text-teal-400">(You can choose multiple)</span></label>
                                        <div class="flex gap-3 lg:gap-4 flex-wrap">
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">Mobile Development</button>
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">Software Development</button>
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">API Integration</button>
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">SQA Solution</button>
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">Apps Design</button>
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">Digital Marketing</button>
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">Web Development</button>
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">Web UX/UI Design</button>
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">Custom Service</button>
                                        </div>
                                    </div>


                                    <div class="flex flex-col gap-4">
                                        <label class="text-gray-300 font-medium text-base">Your Budget (Optional)</label>
                                        <div class="flex gap-3 lg:gap-4 flex-wrap">
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">Less than > $5,00</button>
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">$5,00 - $1,000</button>
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">$1,001 - $1500</button>
                                            <button class="py-2 px-4 lg:py-3 lg:px-6 lg:max-h-[48px] border rounded-lg text-xs lg:text-base font-medium border-[#D2D6DB] text-primary-1">$1500 - $2,000</button>
                                        </div>
                                    </div>

                                    <button
                                        type="submit"
                                        className="bg-teal-800 hover:bg-teal-700 px-8 text-xl font-bold py-3 rounded-lg text-gray-200 w-fit"
                                    >
                                        Send Message
                                    </button>
                                </form>
                            </div>
                            {/* ----------------------------------------------- */}










                            <ContactFormRight />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BuildProduct