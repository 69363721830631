import React from 'react'
import { SlCalender } from "react-icons/sl";
import { Link, useParams } from 'react-router-dom';
import DevelopmentProcess from '../components/DevelopmentProcess';
import StackPowers from '../components/StackPowers';
import { useTitle } from '../useTitle';
import SocialContacts from '../components/SocialContacts';
import teamImg from '../assets/bgElement/TeamworkBg-01.svg'
import ChooseusImg from "../assets/WhyChooseUsL1-01.svg";
import Contactus from '../components/ExpertiseArea';

const ServiceDetails = () => {
    const services = [
        { id: 1, title: "Software Solution", description: "We specialize in crafting innovative and user-centric digital products that span the entire lifecycle from concept to deployment. Our expert team collaborates closely with clients to thoroughly understand their vision, goals, and challenges, ensuring that the final product is not only functional but also exceeds expectations in terms of quality and user experience. We employ the latest technologies and methodologies to deliver software solutions that are scalable, secure, and tailored to meet the specific needs of our clients. Our comprehensive approach includes meticulous planning, agile development, rigorous testing, and ongoing support, guaranteeing that the delivered products are robust, efficient, and capable of driving business success. Whether you need a cutting-edge mobile application, a sophisticated web platform, or an integrated enterprise solution, our team is dedicated to bringing your vision to life with unparalleled precision and excellence." },
        { id: 2, title: 'Web and Mobile App Development', description: "Our team is dedicated to developing robust and scalable web and mobile applications that are tailored to meet the unique needs of your business. We understand that each business has its own set of challenges and requirements, and we strive to create solutions that address these specific needs. Our development process begins with a thorough understanding of your business objectives and target audience. We then design and develop applications that are not only visually appealing but also offer seamless functionality and a superior user experience. Our expertise spans a wide range of technologies and platforms, ensuring that we can deliver the most appropriate solution for your project. Whether you need a responsive website, a feature-rich mobile app, or a complex enterprise system, we have the skills and experience to deliver high-quality, scalable solutions that drive business growth and efficiency." },
        { id: 3, title: 'Continuous Support & Maintenance', description: "Ensuring the ongoing success and optimal performance of your digital products requires a dedicated and comprehensive support and maintenance strategy. Our team provides a wide range of services designed to keep your applications running smoothly and efficiently. From regular updates and bug fixes to performance optimization and security enhancements, we offer proactive solutions to address any issues that may arise. Our support services are tailored to meet the specific needs of your business, ensuring that your applications remain up-to-date with the latest technologies and industry standards. We also provide continuous monitoring and real-time reporting to identify potential problems before they impact your operations. By partnering with us for your support and maintenance needs, you can focus on your core business activities while we take care of the technical details, ensuring that your digital products continue to deliver value and drive success." },
        { id: 4, title: 'QA & testing', description: "Quality assurance and testing are integral components of the software development lifecycle, ensuring that your applications meet the highest standards of quality and reliability. Our comprehensive QA and testing services are designed to identify and address any issues before they reach your end-users. We employ a wide range of testing methodologies and tools to thoroughly evaluate your applications, from functional and performance testing to security and usability testing. Our team of experienced QA professionals works closely with your development team to integrate testing into every phase of the development process, ensuring that quality is built into your products from the ground up. In addition to our integrated QA services, we also offer standalone testing services for clients who need independent verification and validation of their applications. By partnering with us for your QA and testing needs, you can ensure that your products deliver a seamless and reliable user experience, driving customer satisfaction and business success." },
        { id: 5, title: 'E-commerce Development', description: "Taking your business online requires a comprehensive and strategic approach to e-commerce development. Our team specializes in creating sophisticated e-commerce solutions that enable seamless trading capabilities and enhance your brand’s online presence. We understand the unique challenges and opportunities of the e-commerce landscape, and we work closely with you to develop a solution that aligns with your business objectives and target audience. Our e-commerce development services include everything from designing and developing user-friendly online stores to integrating advanced features such as payment gateways, inventory management, and customer relationship management. We also provide ongoing support and maintenance to ensure that your e-commerce platform remains up-to-date and secure. Whether you are launching a new online store or looking to enhance your existing e-commerce capabilities, our team has the expertise and experience to deliver a solution that drives sales, improves customer satisfaction, and supports your business growth." },
        { id: 6, title: 'Application security', description: "In today’s digital landscape, ensuring the security of your applications is more important than ever. Our comprehensive application security services are designed to protect your digital assets from potential threats and vulnerabilities. We employ a multi-faceted approach to security, including thorough audits, rigorous testing, secure development practices, and ongoing training. Our security experts work closely with your development team to identify and address potential vulnerabilities at every stage of the software development lifecycle. We also provide continuous monitoring and real-time threat detection to ensure that your applications remain secure and compliant with industry standards and regulations. By partnering with us for your application security needs, you can protect your business from cyber threats, safeguard your customer data, and maintain the trust and confidence of your users." },
        { id: 7, title: 'ERP', description: "Enterprise Resource Planning (ERP) is a critical component of modern business operations, enabling organizations to streamline processes, improve efficiency, and gain real-time visibility into their operations. Our ERP solutions are designed to automate all facets of your business, from workflow automation and process visibility to data management and real-time reporting. We work closely with you to understand your specific business needs and develop a customized ERP solution that aligns with your goals and objectives. Our ERP solutions are scalable and flexible, allowing you to easily adapt to changing business requirements and growth. We also provide ongoing support and maintenance to ensure that your ERP system remains up-to-date and performs at its best. By partnering with us for your ERP needs, you can improve operational efficiency, reduce costs, and gain the insights you need to drive business success." },
        { id: 8, title: 'CRM', description: "Customer Relationship Management (CRM) is essential for managing interactions with your customers and improving customer satisfaction. Our CRM solutions are designed to provide a comprehensive and integrated approach to customer management, empowering your marketing, sales, and service teams with the tools they need to succeed. We create multifunctional CRM systems that enable you to capture and analyze customer data, streamline processes, and improve communication and collaboration across your organization. Our CRM solutions are customizable and scalable, allowing you to tailor them to your specific business needs and objectives. We also provide ongoing support and maintenance to ensure that your CRM system continues to deliver value and drive customer engagement. By partnering with us for your CRM needs, you can improve customer satisfaction, increase sales, and build long-term customer relationships." },
        { id: 9, title: 'Custom Development', description: "In a competitive digital landscape, having a unique and tailored web solution can set your business apart. Our custom development services are designed to create bespoke web solutions that align precisely with your unique specifications and brand identity. We work closely with you to understand your business needs and objectives, and we develop a solution that reflects your vision and goals. Our custom development services include everything from designing and developing websites and applications to integrating advanced features and functionalities. We employ the latest technologies and best practices to ensure that your custom solution is scalable, secure, and delivers a superior user experience. By partnering with us for your custom development needs, you can create a digital presence that stands out from the competition, engages your audience, and drives business success." },
    ];

    const { id } = useParams();
    const service = services.find(service => service.id === parseInt(id));
    useTitle(`${service.title}`);

    return (
        <section className=''>
            <SocialContacts />
            <div className="containerbg relative">


                <div className="containerbg relative rounded-b-[30px] border-b-4 border-teal-700 md:rounded-b-[100px] w-full h-[400px] flex flex-col items-center pb-5 justify-end m-auto">
                    <h2 className='fontCabinet text-center text-xl 3xl:text-4xl font-bold text-teal-400 uppercase z-30'>Service Details
                    </h2>
                    <p className=' text-sm sm:text-xl text-white text-center px-5 pt-3 z-20'>
                        {service.title}
                    </p>
                    <img src={ChooseusImg} alt="" className=' absolute bottom-0 w-[400px] opacity-[0.5] z-10' />
                    <img className='w-[350px] hidden sm:block object-cover absolute bottom-0 right-0 opacity-[0.4]' src={teamImg} alt="" />
                    <img className='w-[350px] hidden sm:block object-cover absolute bottom-0 left-0 opacity-[0.4]' src={teamImg} alt="" />
                </div>


                <div className="w-full md:w-[768px] lg:w-[80%] 3xl:w-[1536px] py-[40px] flex flex-col justify-center m-auto">
                    <div className="flex flex-col xl:flex-row gap-5 xl:gap-10 px-5 xl:px-0 py-10">
                        <div className="flex-1">
                            <h2 className=' text-teal-400 fontCabinet text-3xl 3xl:text-4xl font-bold pb-5'>{service.title}</h2>
                            <p className='fontCabinet text-gray-300 3xl:text-xl font-medium '>
                                {service.description}
                            </p>
                            <Link to='/contact/'>
                                <button className=' my-5 px-8 py-5 border-2 border-gray-700 containerbg hover:bg-teal-600 rounded text-white font-medium flex gap-2 items-center'> <span> Schedule Appointment</span> <SlCalender className=' text-2xl' /></button>
                            </Link>
                        </div>
                        <div className="flex-1">
                            <div className="">
                                <ul className='cardbg text-gray-300 text-lg font-medium flex flex-col gap-10 p-5 xl:p-10 rounded-xl rounded-br-[80px]' >
                                    <li>
                                        <span>Custom App Development</span>
                                    </li>
                                    <li>
                                        <span>Platform Diversity</span>
                                    </li>
                                    <li>
                                        <span>Scalable Solutions</span>
                                    </li>
                                    <li>
                                        <span>Seamless Integration</span>
                                    </li>
                                    <li>
                                        <span>Security and Compliance</span>
                                    </li>
                                    <li>
                                        <span>User-Centric Design</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="">
                    <Contactus/>
                </div>
                <div className=""></div>
                <div className=" py-16 mb-16">
                    <StackPowers />
                </div>

                <div className="w-full md:w-[768px] lg:w-[80%] 3xl:w-[1536px] py-[40px] flex flex-col justify-center m-auto">

                    <div className="containerbg bg-white rounded-bl-[150px] rounded-xl p-5 xl:px-10">
                        <DevelopmentProcess />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetails