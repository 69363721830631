import React from 'react'
import image1 from '../assets/team/aboutphoto1.jpeg'
import image2 from '../assets/team/aboutphoto2.webp'
import image3 from '../assets/team/aboutphoto3.jpeg'
import imagebg from '../assets/aboutbg.webp'
import { useTitle } from '../useTitle'
import SocialContacts from '../components/SocialContacts'

export const Profile = () => {
    useTitle("About");
    return (
        <section className='containerbg w-full overflow-hidden'>
             <SocialContacts/>
            <div className="shadow-xl containerbg relative rounded-b-[30px] sm:rounded-b-[100px] w-full h-[400px] flex flex-col items-center justify-center m-auto">
                <div className="text-gray-300 p-5 3xl:p-0 flex flex-col items-center text-center 3xl:mt-[80px]">
                    <h2 className='fontCabinet text-xl 3xl:text-5xl font-bold uppercase'>We are your product team
                        in the cloud.</h2>
                    <p className='w-full flex pt-5 text-sm 3xl:text-xl'>
                        We are a team of passionate designers and developers who love building great digital products and experiences.
                        <br />
                        We do everything from research, planning, execution, deployment and quality assurance.


                    </p>
                </div>
            </div>
            <br />
            <div className=" px-5 3xl:px-0 3xl:w-[1536px] py-6 3xl:py-[80px] flex flex-col justify-center m-auto">

                <div className=" text-gray-300 flex flex-col md:flex-row gap-10">
                    <div className="flex-1">
                        <div className="">
                            <h2 className='fontCabinet text-teal-400 capitalize 3xl:text-5xl font-bold py-5'>Who We Are</h2>
                            <p className='3xl:text-xl'>Software Chamber is a leading provider of software development and testing services. With talented software engineers on board, we create engaging web, desktop, and mobile applications for our clients.
                                Since our founding, we have worked with a variety of companies to bring operational benefits to startups, developing, and established organizations.</p>
                        </div>


                        <div className="pt-10">
                            <h2 className='fontCabinet text-teal-400 capitalize 3xl:text-3xl font-bold py-5'>What We Do</h2>
                            <div className='3xl:text-xl'>
                                We guide our clients through their digital transformation by providing competent and dedicated teams that can tackle the whole process from idea to reality.
                                <br /> <br />
                                Our secret sauce is the focus and attention to the small details that make a software team great. We take huge pride in recruiting amazing talents and retain them for a long period of time while also putting a great emphasis on building a culture based on collaboration, trust and curiosity.
                                <br /> <br />
                                We carefully hand pick full time developers who are willing to commit to our mission to build better products and services for our clients in a cost efficient and collaborative way.
                                <br /> <br />
                                <h2 className='font-bold text-teal-400'>Most of our clients love to work with us because</h2>
                                <br /> 

                                <ul className='list-disc flex flex-col items-start gap-3'>
                                    <li>We listen to their business needs</li>
                                    <li>We understand how to build great products and services
                                    </li>
                                    <li>We are a cost effective addition to their existing team</li>
                                    <li>We constantly challenge ourselves to learn new things and keep our clients in the loop
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="fontCabinet containerbg overflow-hidden cursor-pointer mt-10
                        relative group
                        flex items-center gap-3 py-3.5 px-8 border-[1px] rounded  w-fit
                            text-gray-300 text-xl font-medium
                   ">
                            <span className="absolute bottom-0 left-0 w-0 h-[10px] bg-teal-800 group-hover:w-full group-hover:transition-all"></span>

                            <button>Know More About Us</button>

                        </div>
                        <br />


                    </div>

                    <div className="flex-1">


                        <h2 className='fontCabinet text-teal-400 capitalize 3xl:text-5xl font-bold py-5'>Values</h2>

                        <div className="text-gray-300 grid grid-cols-2 items-center ">
                            <div className=" containerbg w-full h-[300px] flex m-auto items-center justify-center">
                                <div className="p-5">
                                    <h3 className='fontCabinet text-xl 3xl:text-4xl font-bold pb-5'>Quality comes first.</h3>
                                    <p className=' text-sm 3xl:text-lg font-medium'>Quality is defined as timely and cost-effective solutions that exceed client expectations.</p>
                                </div>
                            </div>
                            <div className=" w-full h-[300px]">
                                <img className='w-full h-full object-cover rounded-tr-[150px] blur-[2px] hover:blur-none transition-[0.3] ease-out ' src={image1} alt="" />
                            </div>
                            <div className=" w-full h-[300px]">
                                <img className='w-full h-full object-cover blur-[2px] hover:blur-none transition-[0.3] ease-out ' src={image2} alt="" />
                            </div>
                            <div className=" containerbg w-full h-[300px] flex m-auto items-center justify-center">
                                <div className="p-5">
                                    <h3 className='fontCabinet text-xl 3xl:text-4xl font-bold pb-5'>Create customer value.</h3>
                                    <p className=' text-sm 3xl:text-lg font-medium'>We define value for customers as solutions that boost sales, improve operational efficiencies, and lower costs and risks.</p>
                                </div>
                            </div>
                            <div className=" containerbg w-full h-[300px] flex m-auto items-center justify-center">
                                <div className="p-5">
                                    <h3 className='fontCabinet text-sm 3xl:text-4xl font-bold pb-5'>Create Stakeholder Value</h3>
                                    <p className=' text-sm 3xl:text-lg font-medium'>We believe the purpose of business is to create value for all stakeholders, including our employees and community.
                                    </p>
                                </div>
                            </div>
                            <div className=" w-full h-[300px]">
                                <img className='w-full h-full object-cover blur-[2px] hover:blur-none transition-[0.3] ease-out ' src={image3} alt="" />
                            </div>
                        </div>

                    </div>
                </div>


                <div className=" py-[80px] 3xl:pb-0 text-center ">
                    <h2 className='fontCabinet text-teal-400 text-xl 3xl:text-5xl font-bold capitalize'>How we are different</h2>
                    <p className='text-[16px] sm:text-xl font-medium pt-3 text-gray-300'>
                        We strive to build stable technology with beautiful interfaces that merge to become delightful experiences for our clients and their users.

                        <span className='font-bold text-teal-400'> Software chamber</span> is an attractive workplace that puts a strong emphasis on using a proven technology stack together with enabling a new generation of developers to grow in a great work environment and to learn and experiment among like minded people.
                    </p>
                </div>
            </div>



            <section className="relative w-full"
                style={{
                    backgroundAttachment: 'fixed',
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `linear-gradient(to bottom, rgba(24, 83, 81, 0.30), rgba(23, 42, 54, 1)),
                    url(${imagebg})`,
                    width: '100%',
                    height: '500px',
                    backgroundSize: 'cover',
            
                  }}
            >

                {/* <div className=" w-full h-[500px] bottom-0">
                <img className='w-full h-full object-cover' src={imagebg} alt="" />
                </div> */}

                <div className="3xl:w-[1536px] h-full flex items-center justify-center m-auto">
                    <div className="flex flex-col 3xl:flex-row gap-5 text-white">
                        <div className=" text-center w-[400px] p-5">
                            <h2 className='text-4xl font-bold pb-5'>Our Mission</h2>
                            <p className='text-lg font-medium'>Our mission is to provide top-notch software development services that drive business success and enhance operational efficiency.</p>
                        </div>
                        <div className="text-center w-[400px] p-5">
                            <h2 className='text-4xl font-bold pb-5'>Our Vision</h2>
                            <p className='text-lg font-medium'>To be a global leader in software development, empowering businesses with cutting-edge technology solutions.</p>
                        </div>
                    </div>
                </div>
            </section>

        </section>
    )
}
