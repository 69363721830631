import React from 'react'
import { GiWorld } from "react-icons/gi";
import { BiSolidDevices } from "react-icons/bi";
import { CgFigma } from "react-icons/cg";
import { MdManageAccounts } from "react-icons/md";
import { MdHighQuality } from "react-icons/md";
import { MdSupportAgent } from "react-icons/md";
import StackPowers from '../components/StackPowers';
import { useTitle } from '../useTitle';
import SocialContacts from '../components/SocialContacts';

const Services = () => {
    
    useTitle("Services");

    const servicesList = [
        {
            id: 1, title: "Customized Software Product Development",
            description: "We are specialized in building innovative and user-centered digital products from concept to deployment. Our team collaborates closely with clients to understand their vision and deliver exceptional products that exceed expectations.",
            image:< GiWorld className='text-7xl flex items-center justify-center m-auto'/>
        },
        {
            id: 2, title: "Web and Mobile App Development",
            description: "We create robust and scalable web and mobile applications tailored to meet specific business needs.",
            image:< BiSolidDevices className='text-7xl flex items-center justify-center m-auto'/>
        },
        {
            id: 3, title: "UI/UX Design",
            description: "Our talented designers craft visually stunning and intuitive user interfaces that captivate users and enhance brand experiences.",
            image:<CgFigma className='text-7xl flex items-center justify-center m-auto'/>
        },
        {
            id: 4, title: "Project Management",
            description: "We employ agile methodologies and efficient tools to ensure smooth project execution and client collaboration.",
            image:<MdManageAccounts className='text-7xl flex items-center justify-center m-auto'/>
        },
        {
            id: 5, title: "Quality Assurance",
            description: "Rigorous testing is performed to deliver products with the highest standards of performance and reliability.",
            image:<MdHighQuality className='text-7xl flex items-center justify-center m-auto'/>
        },
        {
            id: 6, title: "Continuous Support and Maintenance",
            description: "We provide comprehensive support and maintenance services to ensure the continued success of your products.",
            image:<MdSupportAgent className='text-7xl flex items-center justify-center m-auto'/>
        },
    ]


    return (
        <section className='containerbg w-full'>
             <SocialContacts/>
            <div className="shadow-xl containerbg relative rounded-b-[30px] sm:rounded-b-[100px] w-full h-[400px] flex flex-col items-center justify-center m-auto">
                <div className="text-gray-300 p-5 3xl:p-0 flex flex-col items-center text-center 3xl:mt-[80px]">
                    <h2 className='fontCabinet text-xl 3xl:text-5xl font-bold uppercase'>Unleash the ⚡️ Power of Technology...</h2>
                    <p className='w-full flex items-center justify-center pt-5 text-sm 3xl:text-xl'>
                        Together with our passionate team at
                    </p>
                </div>
            </div>

            <div className=" relative px-3 3xl:px-0 3xl:w-[1536px] py-[40px] 3xl:py-[80px] flex flex-col justify-center m-auto">


               



                <div className=" pb-16">
                    <h2 className=' text-gray-300 text-center pb-3 text-3xl xl:text-5xl font-bold capitalize'> <span className='text-teal-600'>Software Chamber</span> is a holistic partner for your business</h2>
                    <p className='text-gray-300 text-xl text-center font-medium '> Explore our wide range of services to meet your business needs.</p>

                </div>

                <div className="container sm:px-[20px] xl:px-[150px]">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-timeline">

                                {
                                    servicesList?.map((servicesList) => (

                                        <div key={servicesList.id} className="timeline">
                                            <div className="timeline-content text-gray-300">
                                                <div className="timeline-icon">{servicesList.image}</div>
                                                <h3 className="title">{servicesList.title}</h3>
                                                <p className="description">
                                                    {servicesList.description}
                                                </p>
                                            </div>
                                        </div>

                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>

                <div className="capitalize pt-16 xl:pt-[100px] text-center">
                    <h2 className=' pb-3 text-teal-600 text-2xl sm:text-3xl 3xl:text-5xl font-bold py-5 '>
                        We build amazing products together with You.
                    </h2>
                    <p className='text-gray-400 text-md sm:text-xl font-medium '>
                        We are passionate about crafting products that bring your ideas to life. As a leading product agency specializing in Python, Django, JavaScript, and React development, we possess the expertise to create outstanding solutions that is tailor made for your business.
                        Our team of experienced developers is dedicated to delivering great software that drives innovation and propels your business forward.
                    </p>
                </div>

            </div>



            <div className="pb-16">
                    <StackPowers />
                </div>

        </section>
    )
}

export default Services