import React from 'react';
import ChooseusImg from "../assets/WhyChooseUsL1-01.svg";
import abstractCircle from '../assets/bgElement/abstract circle-01.svg';
import SmartProductService from './SmartProductService';
import StackPowers from './StackPowers';

const WhyChooseUs = () => {
  return (
    <section className='containerbg pb-[80px]'>
      <div className='containerbg relative md:overflow-hidden rounded-b-[100px] 3xl:rounded-b-[500px] border-b-[30px] border-b-[#1f6e6c] shadow-2xl shadow-[#1f6e6c] w-full rounded-t-[50px] 3xl:rounded-t-[200px]'>
       
        <div className="hidden sm:block w-[400px] h-[400px] absolute top-[80px] -left-[140px] opacity-[0.2]">
          <img className='w-full h-full object-cover' src={abstractCircle} alt="Abstract Circle" />
        </div>
              
        <div className="rounded-md px-3 py-10 w-full lg:w-[95%] xl:w-[80%] 3xl:[1536px] flex-col items-stretch justify-center m-auto z-[100]">
          <SmartProductService />

          <div className="pb-[20px] 3xl:pb-[50px] sm:pt-[10px]">
            <p className='text-gray-300 text-center py-3 text-sm font-medium'>What we do for you?</p>
            <h1 className='fontCabinet text-center text-2xl sm:text-3xl 3xl:text-5xl font-bold text-teal-400'>Why Choose Us</h1>
          </div>

          <div className="relative w-full pt-[30px] sm:pt-[50px] pb-6 3xl:pb-16 flex flex-col sm:flex-row items-center justify-between gap-10 3xl:gap-0 3xl:w-[80%] m-auto">
            <div className="flex-1 z-[1111]">
              <div className="relative flex flex-col gap-5">
                {[
                  { title: 'Listening to Biz', text: 'We actively listen to understand client needs, ensuring our solutions align with their business goals and expectations.' },
                  { title: 'Crafting Excellence', text: 'We build high-quality products and services, using best practices, rigorous testing, and a commitment to excellence.' },
                  { title: 'Affordable', text: 'We provide cost-effective solutions, optimizing resources to deliver maximum value within your budget constraints.' },
                  { title: 'Client Transparency', text: 'We ensure clear, consistent communication, sharing progress and addressing concerns to keep clients informed and involved.' },
                ].map((item, index) => (
                  <div key={index} className="ChooseUsCard sticky top-[85px] p-7 xl:w-[60%] text-gray-300">
                    <h2 className='text-2xl font-bold border-l-[5px] pl-2 border-teal-400'>{item.title}</h2>
                    <p className='text-sm pt-3'>{item.text}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-auto">
              <img className='-z-[1] whyChooseUs sm:w-[700px] sm:h-[700px] object-cover' src={ChooseusImg} alt="Choose Us" />
            </div>

            <div className="flex-1 z-[1111]">
              <div className="relative flex flex-col gap-5 items-end">
                {[
                  { title: 'Expertise', text: 'With over a decade of experience, our team possesses deep industry knowledge and continuously learns new trends.' },
                  { title: 'Support', text: 'Our relationship extends beyond delivery, offering ongoing support, training, and assistance to ensure client satisfaction.' },
                  { title: 'Technologies', text: 'We leverage the latest tools and technologies, creating innovative, cutting-edge software solutions for our clients.' },
                  { title: 'Proven Success', text: 'Our track record includes successful projects across various industries, backed by client testimonials and measurable results.' },
                ].map((item, index) => (
                  <div key={index} className="ChooseUsCard sticky top-[85px] p-7 xl:w-[60%] text-gray-300">
                    <h2 className='text-2xl font-bold border-l-[5px] pl-2 border-teal-400'>{item.title}</h2>
                    <p className='text-sm pt-3'>{item.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <StackPowers />
        </div>
        <div className="hidden sm:block w-[400px] h-[400px] absolute bottom-0 right-0 opacity-[0.2]">
          <img className='w-full h-full object-cover rotate-180' src={abstractCircle} alt="Abstract Circle" />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
