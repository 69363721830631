import React, { useEffect, useState } from 'react';
import settingimage1 from "../assets/svg setting icon.svg";
import video from '../assets/herovideo.mp4'
import worldImage from '../assets/bgElement/dotted round-01.svg'
import hand from '../assets/bgElement/aiHand-01.svg'
import { Link } from 'react-router-dom';
import Services from './Services';
import DevelopmentProcess from './DevelopmentProcess';
import { IoArrowRedoSharp } from "react-icons/io5";
import Elementor from './Elementor';


const Hero = () => {
  const [, setRotation1] = useState(0);
  const [, setRotation2] = useState(0);

  useEffect(() => {
    const interval1 = setInterval(() => {
      setRotation1((prevRotation) => prevRotation + 10);
    }, 2000);

    const interval2 = setInterval(() => {
      setRotation2((prevRotation) => prevRotation - 10);
    }, 2000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, []);


  // 01. How we work page
  // 02.  team


  // solved fotor color
  // add socialontacts
  // solved hero Link problem
  // Team Here Section Completed
  // HowWeWork page




  return (
    <section className='containerbg z-40 pb-[80px]'>

      <div className="">
        <div className="example-2 overflow-hidden relative z-[1] rounded-b-[100px] 3xl:rounded-b-[600px] border-b-[30px] border-b-[#1f6e6c] shadow-2xl shadow-[rgb(31,110,108)] pt-[60px] lg:pt-[60px] w-[100%] flex items-center justify-center m-auto">

          <div className=' hidden 3xl:block w-fit h-fit absolute top-[460px] -left-[50px] z-[9] rotate-[20deg]'>
            <div className=" w-full h-full">
              <img className='w-[350px] h-[350px] object-cover opacity-[0.5] ' src={hand} alt="hand" />
            </div>
          </div>

          <div className=" overflow containerbg  z-10
          absolute w-full h-full top-0 bottom-0 pointer-events-none mix-blend-screen
          "></div>

          <video src={video} loop muted autoPlay type="video/mp4"
            className='absolute top-0 bottom-0 object-cover w-full h-[40%] lg:h-[60%] xl:h-[80%] 3xl:h-[100%] 3xl:w-[100%] 3xl:right-0 mt-[100px] 3xl:mt-[10px] z-[1]'
          ></video>


          <div className=" px-3 w-full md:w-[768px] lg:w-[1020px] xl:w-[90%] 3xl:w-[1536px] h-max pt-[3rem] lg:pt-[7rem] flex flex-col items-stretch justify-center m-auto z-[9999]">

            <div className="flex pt-[40px] 3xl:pt-0 flex-col lg:flex-row h-full w-full">
              <div className="w-full lg:flex-[40%]">

                <div
                  className=" text-[30px] tracking-[1px] sm:text-[60px] lg:text-[50px] xl:text-[60px] 3xl:text-[60px] font-bold text-teal-400 leading-[35px] sm:leading-[65px] lg:leading-[63px] xl:leading-[65px] 3xl:leading-[70px]">
                  We are your software development team in the cloud
                  <br />

                </div>
                <div className=" text-gray-300 mt-3 sm:mt-5 3xl:mt-[20px] text-md sm:text-lg font-light leading-7 max-md:max-w-full ">
                  Software chamber is a holistic partner for your business. So, Let's Build products together with your dedicated team. It is our joy to have the opportunity to collaborate.
                </div>



                <Link to="contact/">
                  <div className="btncardbg cursor-pointer mt-7 3xl:mt-[50px]
                  relative hover:bg-transparent
                   flex items-center gap-3 py-3 px-5 3xl:py-3 border-[1px] border-teal-400 rounded-lg w-fit
                    text-gray-300 text-sm 3xl:text-xl font-medium
                   ">
                    {/* <span className="absolute bottom-0 left-0 w-0 h-[10px] bg-[#ececec] group-hover:w-full group-hover:transition-all"></span> */}

                    <button>Let's Work Together</button>
                    <span><IoArrowRedoSharp className='text-[25px]' /></span>
                  </div>
                </Link>

              </div>
              <div className=" w-full lg:flex-[60%] relative" >
                <div className=" absolute -top-10 left-3 sm:left-[100px] 3xl:left-[300px] 3xl:-top-[69px]">
                  <div className="h-full relative">

                    <div className=" absolute top-[80px] left-[40px] xl:top-0 xl:left-[33px] ">
                      <Link to="how-we-work/">
                        <div className=" relative w-[200px] sm:w-[300px] hover:transform-[400px] xl:absolute xl:top-[70px] hover:scale-[1.2] hover:z-[9999]">
                          <img src={settingimage1} alt="Software chamber How we work" className='rotateimg1 w-full h-full object-cover' />
                          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-auto text-white font-bold text-[11px] sm:text-[12px] md:text-[14px] lg:text-[15px]'>
                            How we work
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className=" absolute top-[90px] left-[230px] sm:left-[330px] xl:left-[320px] hover:scale-[2] hover:z-[9999]">
                      <Link to='about/'>
                        <div className=" relative w-[80px] sm:w-[130px]">
                          <img src={settingimage1} alt="Software chamber About" className='rotateimg2 w-full h-full object-cover' />
                          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-auto text-white font-bold text-[11px] sm:text-[12px] md:text-[14px] lg:text-[15px]'>
                            About
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className=" absolute top-[172px] left-[240px] sm:top-[222px] sm:left-[335px] hover:scale-[1.5]">
                      <Link to='blog/'>
                        <div className=" relative w-[50px] sm:w-[90px]">
                          <img src={settingimage1} alt="Software chamber Blog" className='rotateimg1 w-full h-full object-cover' />
                          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-auto text-white font-bold text-[11px] sm:text-[12px] md:text-[14px] lg:text-[15px]'>
                            Blog
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className=" absolute top-[224px] left-[205px] sm:top-[310px] sm:left-[278px] hover:scale-[1.5]">
                      <Link to='contact'>
                        <div className=" relative w-[110px] sm:w-[155px]">
                          <img src={settingimage1} alt="Software chamber Contact" className='rotateimg2 w-full h-full object-cover' />
                          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-auto text-white font-bold text-[11px] sm:text-[12px] md:text-[14px] lg:text-[15px]'>
                            Contact
                          </div>
                        </div>
                      </Link>
                    </div>


                    <div className=" absolute top-[280px] left-[73px] sm:top-[380px] sm:left-[88px] hover:scale-[1.2]">
                      <Link to='services/'>
                        <div className=" relative w-[160px] sm:w-[225px]">
                          <img src={settingimage1} alt="Software chamber Services" className='rotateimg1 w-full h-full object-cover' />
                          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-auto text-white font-bold text-[11px] sm:text-[12px] md:text-[14px] lg:text-[15px]'>
                            Services
                          </div>
                        </div>
                      </Link>
                    </div>


                    <div className=" absolute top-[245px] -left-[2px]  sm:top-[460px] sm:left-[] hover:scale-[1.5]">
                      <Link to='reports/'>
                        <div className=" relative w-[95px] sm:">
                          <img src={settingimage1} alt="Software chamber Reports" className='rotateimg2 w-full h-full object-cover' />
                          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-auto text-white font-bold text-[10px] '>
                            Reports
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className=" hidden sm:block sm:absolute absolute top-[245px] -left-[2px] sm:top-[329px] sm:-left-[13px] hover:scale-[2] hover:z-[9999]">
                      <Link to='team/'>
                        <div className=" relative w-[95px] sm:w-[130px]">
                          <img src={settingimage1} alt="Software chamber team" className='rotateimg2 w-full h-full object-cover' />
                          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-auto text-white font-bold text-[11px] sm:text-[12px] md:text-[14px] lg:text-[15px]'>
                            Team
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <Services />
            <DevelopmentProcess />
            <Elementor />

          </div>
        </div>

        <div className=' hidden absolute -left-[300px] -bottom-[230px] z-[8888] opacity-[0.7]'>
          <img className='  w-[500px] h-[500px] object-cover' src={worldImage} alt="" />
        </div>

        <div className='absolute hidden -right-[260px] -bottom-[230px] z-[8888] opacity-[0.7]'>
          <img className='w-[500px] h-[500px] object-cover' src={worldImage} alt="" />
        </div>



      </div>
    </section>
  );
};

export default Hero;
