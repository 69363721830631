import React from "react";
import { Link } from "react-router-dom";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import bgMap from "../assets/World Map3-01.png"

const Footer = () => {
  return (
    <footer className="containerbg">
      <div className=" ">
        <div className="box relative">
          {/* <div className="absolute top-[140px] left-[20%] flex flex-col justify-center items-center m-auto w-fit">
            <div className="gooey-animations">
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '131.619px', animationDelay: '2.86761s', transform: 'translateY(6.94131px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '183.072px', animationDelay: '3.43045s', transform: 'translateY(3.73606px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '800.243px', animationDelay: '0.569149s', transform: 'translateY(1.10596px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '905.275px', animationDelay: '0.189314s', transform: 'translateY(9.02367px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '941.753px', animationDelay: '3.92612s', transform: 'translateY(7.83575px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '411.85px', animationDelay: '0.641401s', transform: 'translateY(3.10314px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '518.452px', animationDelay: '3.61001s', transform: 'translateY(0.837778px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '596.737px', animationDelay: '3.36145s', transform: 'translateY(7.57535px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '904.932px', animationDelay: '2.7057s', transform: 'translateY(2.74688px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '204.481px', animationDelay: '4.85606s', transform: 'translateY(6.35433px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '1106.47px', animationDelay: '1.75941s', transform: 'translateY(4.53238px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '782.747px', animationDelay: '3.93025s', transform: 'translateY(8.92426px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '919.408px', animationDelay: '3.03944s', transform: 'translateY(5.00603px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '668.152px', animationDelay: '1.64199s', transform: 'translateY(9.00368px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '454.826px', animationDelay: '2.48342s', transform: 'translateY(9.08178px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '447.225px', animationDelay: '3.91715s', transform: 'translateY(9.22285px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '1090.3px', animationDelay: '2.07239s', transform: 'translateY(2.27963px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '689.132px', animationDelay: '4.33962s', transform: 'translateY(0.364778px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '1055.53px', animationDelay: '4.7174s', transform: 'translateY(9.63558px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '594.358px', animationDelay: '0.756198s', transform: 'translateY(8.89608px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '84.5px', animationDelay: '0.519195s', transform: 'translateY(0.0825059px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '23.2109px', animationDelay: '2.10703s', transform: 'translateY(3.18157px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '761.018px', animationDelay: '4.18377s', transform: 'translateY(4.05132px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '416.987px', animationDelay: '0.90393s', transform: 'translateY(8.62809px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '96px', animationDelay: '1.23168s', transform: 'translateY(3.22817px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '919px', animationDelay: '3.04802s', transform: 'translateY(5.42143px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '969px', animationDelay: '2.30628s', transform: 'translateY(0.786412px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '939px', animationDelay: '4.35003s', transform: 'translateY(4.89311px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '979px', animationDelay: '0.76039s', transform: 'translateY(0.225525px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '999px', animationDelay: '3.5335s', transform: 'translateY(7.74727px)', backgroundColor: 'rgb(255, 160, 54)' }}></div>
              <div className="ball" style={{ position: 'absolute', bottom: '0px', left: '991px', animationDelay: '3.52766s', transform: 'translateY(2.02566px)', backgroundColor: 'rgb(40, 50, 59)' }}></div>
            
            </div>
          </div> */}
          <div className="box-inner ">

            <div className=" w-[300px] sm:w-[350px] md:w-[600px] xl:w-[900px] pt-16 sm:pt-10 flex items-center justify-center m-auto">
              <form className="w-full">
                <div className=" w-full flex items-center justify-between rounded-xl bg-teal-800 border border-white py-1.5 pl-4 pr-2">
                  <input type="text" placeholder="Email" className="text-sm w-full text-gray-300 bg-transparent border-none outline-none" />
                  <button className="text-sm font-bold text-white rounded-xl bg-teal-600 py-2 px-4">Subscribe</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className=" bg-[#1d3645] py-10 w-full h-full relative overflow-hidden">
        <div className="z-[0] absolute top-0 right-0 w-full h-full pt-[20px]">
          <img className="w-[640px] h-[1000px] sm:w-[768px] md:w-[1224px] xl:w-full xl:h-fit opacity-[0.1] object-cover " src={bgMap} alt="map" />
        </div>

        <div className="px-[20px] md:px-[0px] xl:px-[40px] w-full z-[444]">
          <div className="w-full py-8 flex m-auto justify-center items-center md:px-[50px]">
            <div className="grid gap-5 grid-cols-1 sm:grid-cols-3 w-full md:grid-cols-4 m-auto items-start">
              <div data-aos="fade-right" data-aos-duration="4000">
                <Link to="/" className="cursor-pointer z-50">
                  <p className="text-[15px] text-gray-300 md:text-[20px] xl:text-[25px] flex items-center gap-3 font-bold">
                    {/* <img
                      src=''
                      alt="Company Logo"
                      className="w-[35px] xl:w-[35px] bg-blue-800 p-2 rounded-[50px] shadow-sm"
                    /> */}
                    Software chamber
                  </p>
                </Link>
                <p className="text-gray-50 text-sm mt-5 opacity-80">
                  A team of enthusiastic specialists run the full-service software development company Software chamber, developing specialized software to address complex problems.
                </p>
              </div>

              <div data-aos="fade-up" data-aos-duration="4000">
                <h2 className="mb-[20px] text-[16px] 3xl:text-2xl font-bold text-white">Our Services</h2>
                <ul className="text-gray-50 font-50 text-[13px] md:text-[14px] opacity-80">
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">Web Development</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">Mobile App Development</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">Enterprise Application Development</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">Digital Marketing</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">Software Testing & Quality Assurance</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">IT Consulting</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">Setup Own Hosting</Link>
                  </li>
                </ul>
              </div>

              <div data-aos="fade-down" data-aos-duration="4000">
                <h2 className="mb-[20px] text-[16px] 3xl:text-2xl font-bold text-white">Web Services</h2>
                <ul className="text-gray-50 font-[400] text-[13px] md:text-[14px] opacity-80">
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">UI/UX Design</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">E-commerce</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">ERP Solutions</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">Web Hosting</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">SEO & Internet Marketing</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">Visa Processing Software</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">Doctors Prescription</Link>
                  </li>
                  <li className="mb-[10px]">
                    <Link to="#" className="cursor-pointer">Other Applications</Link>
                  </li>
                </ul>
              </div>

              <div data-aos="fade-left" data-aos-duration="4000">
                <h2 className="mb-[20px] text-[16px] 3xl:text-2xl font-bold text-white">Get In Touch</h2>
                <ul className=" text-gray-50 font-[400] text-[13px] md:text-[14px] opacity-80">
                  <li className="mb-[10px] flex flex-col gap-[10px]">
                    <span className="flex items-center gap-5">
                      <FaPhoneVolume />
                      +88 01830208833
                    </span>
                    <span className="flex items-center gap-5">
                      <MdEmail />
                      softwarechamber@gmail.com
                    </span>
                    <p className="flex items-center gap-5">
                      <FaLocationDot />
                      5/A Hoqshaheb garez,Shyamoli, Dhaka,Bangladesh
                    </p>
                  </li>


                  <li data-aos="fade-up" data-aos-duration="5000 4000 5000"
                    className=" shadow-sm w-fit m-auto text-white shadow-black py-3 px-4 my-10 md:mt-0 flex flex-wrap gap-5 items-center justify-center 3xl:my-10 3xl:m-0" >
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      to=''
                      className=" p-2 bg-[#1DA1F2] cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-twitter transition hover:translate-x-3 "
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                      </svg>
                    </Link>

                    <Link
                      target="_blank"
                      rel="noreferrer"
                      to=''
                      className="cursor-pointer p-2 bg-[#3b5998]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-facebook transition hover:translate-x-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                      </svg>
                    </Link>
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      to=''
                      className="cursor-pointer p-2 bg-[#0072b1]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-linkedin transition hover:translate-x-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                      </svg>
                    </Link>

                    <Link
                      target="_blank"
                      rel="noreferrer"
                      to=''
                      className="cursor-pointer p-2 bg-[#833AB4]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-instagram transition hover:translate-x-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                      </svg>
                    </Link>

                    <Link
                      target="_blank"
                      rel="noreferrer"
                      to=''
                      className="cursor-pointer p-2 bg-[#ce2020] "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-youtube transition hover:translate-x-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                      </svg>
                    </Link>
                  </li>

                </ul>


              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" bg-[#13222c]">
        <div className="px-[20px] md:px-[50px] py-3 text-[12px] font-[400] text-white">
          <div className="flex items-center justify-center gap-4">
            <span className="opacity-75">© Software chamber. 2024</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
