import React from 'react';
import SEO from "../components/SEO";
import SoftwareChamberlogo from "../assets/logo.2.png"
import ExpertiseArea from '../components/ExpertiseArea';
import Faqs from "../components/faqs/Faqs";
import Contactus from "../components/Contactus";
import Hero from "../components/Hero";
import WhyChooseUs from "../components/WhyChooseUs";
import SocialContacts from '../components/SocialContacts';

const Home = () => {
  return (
    <>
      <SEO
        title="Home | Software Chamber"
        description="Software Chamber provides top-notch custom software development, web development, mobile app development, and cloud solutions."
        keywords="software development, web development, mobile app development, cloud solutions, IT consulting, software chamber"
        author="Software Chamber"
        url="http://softwarechamber.com/"
        image={SoftwareChamberlogo}
        additionalMeta={[
          { name: "viewport", content: "width=device-width, initial-scale=1" },
          { name: "robots", content: "index, follow" }
        ]}
      />

      <main className='home-page'>
        <Hero />
        <WhyChooseUs />
        <ExpertiseArea />
        <Contactus />
        <Faqs />

        <SocialContacts/>

      </main>
    </>
  );
};

export default Home;
