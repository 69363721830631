import React from 'react'
import timeImg from '../assets/timeL2-01.png'
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import { FaFacebookMessenger } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from 'react-router-dom'

import { CgMail } from "react-icons/cg";
import { FaLocationDot } from "react-icons/fa6";
import { IoMapSharp } from "react-icons/io5";


const ContactForm = () => {
  return (
    <div className='p-2 3xl:pl-10'>
      <form className='flex flex-col gap-10 p-0 py-0 text-white'>
        <div className=" bg-teal-800 px-3 py-2 rounded-lg">
          {/* <label htmlFor="name" className="block text-lg font-medium mb-2">Name</label> */}
          <input
            type="text"
            id="name"
            name="name"
            placeholder='Your name*'
            className=" z-[-10] w-full placeholder:text-gray-400  py-2 bg-transparent focus:outline-none"
          />
        </div>

        <div className=" bg-teal-800 px-3 py-2 rounded-lg">
          {/* <label htmlFor="COMPANY" className="block text-gray-700 font-medium mb-2">Company</label> */}
          <input
            type="text"
            id="Subject"
            name=""
            placeholder='Subject*'
            className=" z-[-10] w-full placeholder:text-gray-400 py-2 bg-transparent focus:outline-none"
          />
        </div>

        <div className=" bg-teal-800 px-3 py-2 rounded-lg">
          {/* <label htmlFor="COMPANY" className="block text-gray-700 font-medium mb-2">Company</label> */}
          <input
            type="text"
            id="phone"
            name="phone"
            placeholder='Enter Your Phone Number*'
            className=" z-[-10] w-full placeholder:text-gray-400 py-2 bg-transparent focus:outline-none"
          />
        </div>

        <div className=" bg-teal-800 px-3 py-2 rounded-lg">
          {/* <label htmlFor="email" className="block text-gray-700 font-medium mb-2">Email</label> */}
          <input
            type="email"
            id="email"
            name="email"
            placeholder='Enter Your Email*'
            className=" z-[-10] w-full placeholder:text-gray-400  py-2 bg-transparent focus:outline-none"
          />
        </div>
        <div className=" bg-teal-800 px-3 py-2 rounded-lg">
          {/* <label htmlFor="message" className="block text-gray-700 font-medium mb-2">Message</label> */}
          <textarea
            id="message"
            name="message"
            rows="5"
            placeholder='Describe your needs in detail*'
            className=" resize-none z-[-10] w-full placeholder:text-gray-400  py-2 bg-transparent focus:outline-none"
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-teal-800 hover:bg-teal-700 px-8 text-xl font-bold py-3 rounded-lg text-gray-200 w-fit"
        >
          Send Message
        </button>
      </form>
    </div>
  )
}

export default ContactForm




export const ContactFormRight = () => {
  return (
    <div className="pb-5 xl:pb-0 3xl:pt-5">
      <img className='w-full h-fit md:w-[400px] 3xl:w-[400px] object-cover p-2 xl:p-0' src={timeImg} alt="TimeImg" />

      <div className="max-w-[400px] flex flex-row 3xl:pt-10 gap-0 3xl:gap-8 text-gray-300">
        <div className=" cursor-pointer p-3 rounded-full flex items-center justify-center m-auto  w-[100px] h-[100px]  ">
          <Link to='' target="_blank" title="click">
            <FaFacebookMessenger className='text-[50px] hover:text-[60px] transition ease-in-out 3xl:text-[50px]  text-[#1f6e6c]' />
          </Link>
        </div>

        <div className="relative group cursor-pointer p-3 rounded-full flex items-center justify-center m-auto  w-[100px] h-[100px]">
          <Link to='tel:01832-344599' target="_blank" title='01832-344599'>
            <LiaPhoneVolumeSolid className='text-[50px] hover:text-[60px] transition ease-in-out 3xl:text-[50px] 3xl:hover:text-[90px] text-[#1f6e6c]' />
          </Link>
          <span className='absolute hidden top-0 w-full h-fit py-2 font-bold text-gray-300
              group-hover:block group-hover:transition-all
              '>01830208833</span>
        </div>

        <div className=" relative group cursor-pointer p-3 rounded-full flex items-center justify-center m-auto  w-[100px] h-[100px]">
          <Link to='mailto:softwarechamber@gmail.com' target="_blank" title='softwarechamber@gmail.com'>
            <CgMail className='text-[50px] hover:text-[60px] transition ease-in-out 3xl:text-[50px] 3xl:hover:text-[90px] text-[#1f6e6c]' />
          </Link>

          <span className='absolute hidden top-0 w-full h-fit py-2 font-bold text-gray-300
              group-hover:block group-hover:transition-all
              '>softwarechamber@gmail.com</span>
        </div>

        <div className=" cursor-pointer p-3 rounded-full flex items-center justify-center m-auto  w-[100px] h-[100px]">
          <Link to='https://web.whatsapp.com/' target="_blank" title='Click'>
            <IoLogoWhatsapp className='text-[50px] hover:text-[60px] transition ease-in-out 3xl:text-[50px] 3xl:hover:text-[90px] text-[#1f6e6c]' />
          </Link>
        </div>

      </div>






      <p className=' sm:text-[22px] 3xl:text-2xl font-bold pt-0 px-2 3xl:px-4 text-[#267976] flex items-start sm:items-center md:items-start 3xl:items-center'> < FaLocationDot /> 5/A Hoqshaheb garez,Shyamoli, Dhaka,Bangladesh</p>
      <Link className='px-2 3xl:px-4 pt-1 text-sm hover:underline cursor-pointer text-[#267976] flex gap-1 items-center' target="_blank"><IoMapSharp className='text-[20px] text-[#267976]' /> <span>Google map..</span></Link>

    </div>
  )
}
