import './App.css';
import Index from './router/Index';


// style={{ backgroundImage: "radial-gradient(231% 135.8% at -8.62% 2.98%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%)" }}
function App() {
  return (
    <>
      <Index />
    </>
  );
}

export default App;
