import React from 'react';

import fintech from "../assets/IndustriesWeServe/fintech.svg"
import healthCare from "../assets/IndustriesWeServe/healthCare.svg"
import telco from "../assets/IndustriesWeServe/telco.svg"
import eCom from "../assets/IndustriesWeServe/e-com.svg"
import realEstate from "../assets/IndustriesWeServe/realEstate.svg"
import autoMotive from "../assets/IndustriesWeServe/autoMotive.svg"
import software from "../assets/IndustriesWeServe/software.svg"
import startup from "../assets/IndustriesWeServe/startup.svg"
import education from "../assets/IndustriesWeServe/education.svg"
import retails from "../assets/IndustriesWeServe/retails.svg"
import nonProfit from "../assets/IndustriesWeServe/nonProfit.svg"
import miscellaneous from "../assets/IndustriesWeServe/miscellaneous.svg"
import agriculture from "../assets/IndustriesWeServe/agriculture.svg"
import construction from "../assets/IndustriesWeServe/construction.svg"
import energy from "../assets/IndustriesWeServe/energy.svg"
import erpSolution from "../assets/IndustriesWeServe/erpSolution.svg"
import financeBanking from "../assets/IndustriesWeServe/financeBanking.svg"
import govtPublicSector from "../assets/IndustriesWeServe/govtPublicSector.svg"
import hospitality from "../assets/IndustriesWeServe/hospitality.svg"
import informationTechnology from "../assets/IndustriesWeServe/informationTechnology.svg"
import manufacturing from "../assets/IndustriesWeServe/manufacturing.svg"
import mediaEntertainment from "../assets/IndustriesWeServe/mediaEntertainment.svg"


const industries = [
  { src: fintech, alt: "Fintech", title: "Fintech" },
  { src: healthCare , alt: "Health Care", title: "Health Care" },
  { src: telco, alt: "Telco", title: "Telco" },
  { src: eCom, alt: "E-com", title: "E-com" },
  { src: realEstate, alt: "Real Estate", title: "Real Estate" },
  { src: autoMotive, alt: "Auto Motive", title: "Auto Motive" },
  { src: software, alt: "Software", title: "Software" },
  { src: startup, alt: "Startup", title: "Startup" },
  { src: education, alt: "Edu Tech", title: "Edu Tech" },
  { src: retails, alt: "Retails", title: "Retails" },
  { src: nonProfit, alt: "Non Profit", title: "Non Profit" },
  { src: miscellaneous, alt: "Miscellaneous", title: "Miscellaneous" },
  { src: agriculture, alt: "Agriculture", title: "Agriculture" },
  { src: construction, alt: "Construction", title: "Construction" },
  { src: energy, alt: "Energy", title: "Energy" },
  { src: erpSolution, alt: "ERP-Solution", title: "ERP-Solution" },
  { src: financeBanking, alt: "Finance Banking", title: "Finance Banking" },
  { src: govtPublicSector, alt: "Government Public Sector", title: "Government Public Sector" },
  { src: hospitality, alt: "Hospitality", title: "Hospitality" },
  { src: informationTechnology, alt: "Information Technology", title: "Information Technology" },
  { src: manufacturing, alt: "Manufacturing", title: "Manufacturing" },
  { src: mediaEntertainment, alt: "Media Entertainment", title: "Media Entertainment" },
];

const IndustriesWeServe = () => {
  return (
    <div className="pt-16 lg:pt-36">
      <h2 className="heading-one mb-5 lg:mb-10 dark:text-white text-center">
        <span className="text-center text-teal-400 md:text-5xl text-3xl font-semibold">Industries We Serve</span>
      </h2>

      <div className="flex flex-wrap gap-5 items-center justify-center">
        {industries.map((industry, index) => (
          <div key={index} className="m-2 md:m-8 mb-8 md:mb-4">
            <div className="w-[140px] md:w-[195px] h-full rounded-lg px-4 md:px-8 flex flex-col items-center justify-center space-y-3">
              <img className="h-[50px] w-[50px]" src={industry.src} alt={industry.alt} />
              <div className="text-white text-center text-xl font-bold h-[60px]">
                {industry.title}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IndustriesWeServe;
