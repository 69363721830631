import React from 'react'
import { Link, useParams } from 'react-router-dom'
import blogimage from '../assets/blog/blog-01.jpg'
import { useTitle } from '../useTitle';
import BlogLeft from '../components/BlogLeft';

import { LiaCommentsSolid } from "react-icons/lia";
import { FaRegClock } from "react-icons/fa";
import { BsTags } from "react-icons/bs";

import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { IoLogoLinkedin } from "react-icons/io";
import { AiFillGooglePlusCircle } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";


const BlogDetails = () => {

    const blogPosts = [
        {
            id: 1,
            title: 'Doccure – Making your clinic painless visit?',
            author: 'Dr. Ruby Perrin',
            date: '4 Dec 2019',
            comments: 12,
            tags: 'Health Tips',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
            image: blogimage
        },
        {
            id: 2,
            title: 'Doccure – Making your clinic painless visit?',
            author: 'Dr. Ruby Perrin',
            date: '4 Dec 2019',
            comments: 12,
            tags: 'Health Tips',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
            image: blogimage
        },
        {
            id: 3,
            title: 'Doccure – Making your clinic painless visit?',
            author: 'Dr. Ruby Perrin',
            date: '4 Dec 2019',
            comments: 12,
            tags: 'Health Tips',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
            image: blogimage
        },
        {
            id: 4,
            title: 'Doccure – Making your clinic painless visit?',
            author: 'Dr. Ruby Perrin',
            date: '4 Dec 2019',
            comments: 12,
            tags: 'Health Tips',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
            image: blogimage
        },
        {
            id: 5,
            title: 'Doccure – Making your clinic painless visit?',
            author: 'Dr. Ruby Perrin',
            date: '4 Dec 2019',
            comments: 12,
            tags: 'Health Tips',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
            image: blogimage
        },
        {
            id: 6,
            title: 'Doccure – Making your clinic painless visit?',
            author: 'Dr. Ruby Perrin',
            date: '4 Dec 2019',
            comments: 12,
            tags: 'Health Tips',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
            image: blogimage
        },
        {
            id: 7,
            title: 'Doccure – Making your clinic painless visit?',
            author: 'Dr. Ruby Perrin',
            date: '4 Dec 2019',
            comments: 12,
            tags: 'Health Tips',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
            image: blogimage
        },
        {
            id: 8,
            title: 'Doccure – Making your clinic painless visit?',
            author: 'Dr. Ruby Perrin',
            date: '4 Dec 2019',
            comments: 12,
            tags: 'Health Tips',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
            image: blogimage
        },
    ];


    const latestPosts = [
        {
            title: 'Latest Post 1',
            date: '4 Dec 2019',
            image: blogimage
        },
        {
            title: 'Latest Post 2',
            date: '5 Dec 2019',
            image: blogimage
        },
        {
            title: 'Latest Post 2',
            date: '5 Dec 2019',
            image: blogimage
        },
        {
            title: 'Latest Post 2',
            date: '5 Dec 2019',
            image: blogimage
        },
        {
            title: 'Latest Post 2',
            date: '5 Dec 2019',
            image: blogimage
        },
    ];

    const { id } = useParams();
    const blogPost = blogPosts.find(blogPost => blogPost.id === parseInt(id));

    useTitle(`${blogPost.title}`);


    return (
        <section>
            <div className="pt-[150px] containerbg">
                <div className="w-full px-3 md:px-0 md:w-[768px] lg:w-[80%] 3xl:w-[1336px] flex flex-col justify-center m-auto">
                    <div className="content">
                        <div className="container">
                            <div className="row flex flex-col md:flex-row gap-5">

                                <div className="flex flex-col gap-5 md:flex-[60%] 3xl:flex-[70%]">
                                    <div className="blog">
                                        <div className="blog-image">
                                            <Link to=''>
                                                <img className="img-fluid" src={blogPost.image} alt="Post Image" />
                                            </Link>
                                        </div>
                                        <h3 className="blog-title"><Link to=''>{blogPost.title}</Link></h3>
                                        <div className="blog-info clearfix">
                                            <div className="post-left">
                                                <ul>
                                                    <li>
                                                        <div className="post-author">
                                                            <Link to="">
                                                                <img src={blogPost.image} alt="Post Author" />
                                                                <span>{blogPost.author}</span>
                                                            </Link>
                                                        </div>
                                                    </li>
                                                    <li className='flex items-center gap-1'><LiaCommentsSolid />{blogPost.date}</li>
                                                    <li className='flex items-center gap-1'> <FaRegClock />{blogPost.comments} Comments</li>
                                                    <li className='flex items-center gap-1'><BsTags />{blogPost.tags}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="blog-content">
                                            <p>{blogPost.content}</p>
                                        </div>
                                    </div>




                                    <div className="card blog-share clearfix border border-white pb-5 sm:p-5 rounded-[4px]">
                                        <div className="card-header card-header py-3 text-white font-bold">
                                            <h4 className="card-title">Share the post</h4>
                                        </div>
                                        <div className="card-body">
                                            <ul className="social-share">
                                                <li className=''><Link to="" title="Facebook"><FaFacebook /></Link></li>
                                                <li className=''><Link to="" title="Twitter"><FaTwitter /></Link></li>
                                                <li className=''><Link to="" title="Linkedin"><IoLogoLinkedin /></Link></li>
                                                <li className=''><Link to="" title="Google Plus"><AiFillGooglePlusCircle /></Link></li>
                                                <li className=''><Link to="" title="Youtube"><FaYoutube /></Link></li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* ------------------------------------------------------------About Author------------------------------- */}

                                    <div className="card author-widget clearfix border border-white sm:sm:p-5 rounded-[4px]">
                                        <div className="card-header py-3 text-white font-bold">
                                            <h4 className="card-title">About Author</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="about-author">
                                                <div className="about-author-img">
                                                    <div className="author-img-wrap">
                                                        <Link to=''>
                                                            <img className="rounded-full w-[100px] h-[100px]"
                                                                alt="" src={blogimage} /></Link>
                                                    </div>
                                                </div>
                                                <div className="author-details">
                                                    <Link to='' className="blog-author-name">Dr. Darren Elder</Link>
                                                    <p className="mb-0 text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                        ad minim veniam, quis nostrud exercitation.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* -------------------------------------------------------Comments---------------------------------- */}
                                    <div className="card blog-comments clearfix border border-white sm:p-5 rounded-[4px]">
                                        <div className="card-header py-3 text-white font-bold">
                                            <h4 className="card-title">Comments (12)</h4>
                                        </div>
                                        <div className="card-body pb-0">
                                            <ul className="comments-list">
                                                <li>
                                                    <div className="comment">
                                                        <div className="comment-author">
                                                            <img className="avatar" alt="" src="assets/img/patients/patient4.jpg" />
                                                        </div>
                                                        <div className="comment-block">
                                                            <span className="comment-by">
                                                                <span className="blog-author-name">Michelle Fairfax</span>
                                                            </span>
                                                            <p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                                                                viverra euismod odio, gravida pellentesque urna varius vitae,
                                                                gravida pellentesque urna varius vitae. Lorem ipsum dolor sit
                                                                amet, consectetur adipiscing elit.</p>
                                                            <p className="blog-date">Dec 6, 2017</p>

                                                            <Link className="comment-btn " to="">
                                                                <TiArrowBack className='text-xl' /> <span>Reply</span>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                    <ul className="comments-list reply">
                                                        <li>
                                                            <div className="comment">
                                                                <div className="comment-author">
                                                                    <img className="avatar" alt=""
                                                                        src="assets/img/patients/patient5.jpg" />
                                                                </div>
                                                                <div className="comment-block">
                                                                    <span className="comment-by">
                                                                        <span className="blog-author-name">Gina Moore</span>
                                                                    </span>
                                                                    <p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                        Nam viverra euismod odio, gravida pellentesque urna
                                                                        varius vitae, gravida pellentesque urna varius vitae.
                                                                    </p>
                                                                    <p className="blog-date">Dec 6, 2017</p>
                                                                    <Link className="comment-btn " to="">
                                                                        <TiArrowBack className='text-xl' /> <span>Reply</span>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comment">
                                                                <div className="comment-author">
                                                                    <img className="avatar" alt=""
                                                                        src="assets/img/patients/patient3.jpg" />
                                                                </div>
                                                                <div className="comment-block">
                                                                    <span className="comment-by">
                                                                        <span className="blog-author-name">Carl Kelly</span>
                                                                    </span>
                                                                    <p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                        Nam viverra euismod odio, gravida pellentesque urna
                                                                        varius vitae, gravida pellentesque urna varius vitae.
                                                                    </p>
                                                                    <p className="blog-date">December 7, 2017</p>
                                                                    <Link className="comment-btn " to="">
                                                                        <TiArrowBack className='text-xl' /> <span>Reply</span>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <div className="comment">
                                                        <div className="comment-author">
                                                            <img className="avatar" alt="" src="assets/img/patients/patient6.jpg" />
                                                        </div>
                                                        <div className="comment-block">
                                                            <span className="comment-by">
                                                                <span className="blog-author-name">Elsie Gilley</span>
                                                            </span>
                                                            <p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                            <p className="blog-date">December 11, 2017</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="comment">
                                                        <div className="comment-author">
                                                            <img className="avatar" alt="" src="assets/img/patients/patient7.jpg" />
                                                        </div>
                                                        <div className="comment-block">
                                                            <span className="comment-by">
                                                                <span className="blog-author-name">Joan Gardner</span>
                                                            </span>
                                                            <p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                            <p className="blog-date">December 13, 2017</p>
                                                            <Link className="comment-btn " to="">
                                                                <TiArrowBack className='text-xl' /> <span>Reply</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    {/* ----------------------------------------------------Leave Comment------------------------ */}
                                    <div className="card new-comment clearfix border border-white sm:p-5 rounded-[4px]">
                                        <div className="card-header py-3 text-white font-bold">
                                            <h4 className="card-title">Leave Comment</h4>
                                        </div>

                                        <div className="card-body">
                                            <form className='flex flex-col gap-8'>
                                                <div className="form-group text-white">
                                                    <label>Name <span className="text-red-500">*</span></label>
                                                    <input type="text" className="w-full mt-3 bg-transparent border py-2.5 px-3 rounded-md"/>
                                                </div>
                                                <div className="form-group text-white">
                                                    <label>Your Email Address <span className="text-red-500">*</span></label>
                                                    <input type="email" className="w-full mt-3 bg-transparent border py-2.5 px-3 rounded-md"/>
                                                </div>
                                                <div className="form-group text-white">
                                                    <label>Comments</label>
                                                    <textarea rows="4" className="w-full resize-none border border-white bg-transparent  rounded-md mt-3 p-3"></textarea>
                                                </div>
                                                <div className="w-fit">
                                                    <button className=" text-white px-8 py-3 border border-white rounded-sm hover:bg-teal-400" type="submit">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>






                                </div>



                                <BlogLeft latestPosts={latestPosts} />


                            </div>
                        </div>
                    </div>





                </div>
            </div>
        </section>
    )
}

export default BlogDetails