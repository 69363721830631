import { useEffect } from "react";

// Custom hook to set the document title
export const useTitle = (title) => {
    useEffect(() => {
        document.title = `${title} | Softwarec Chamber`;
    }, [title]);
    
    return null;
}
