import React from 'react'
import abstractCircle from '../assets/bgElement/abstract circle-01.svg'

import fahim from '../assets/team/fahim.jpg'
import habib from '../assets/team/habib.JPG'
import iqbal from '../assets/team/iqbal.JPG'
import ismail from '../assets/team/ismail.jpg'
import mishu from '../assets/team/mishu.png'

import { FaFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { Link } from 'react-router-dom'
import { useTitle } from '../useTitle'
import SocialContacts from '../components/SocialContacts'
import teamHero from "../assets/team/teamHero.png"
import teamImg from '../assets/bgElement/TeamworkBg-01.svg'

import TeamDevelopmentProcess from '../components/TeamDevelopmentProcess'

import ChooseusImg from "../assets/WhyChooseUsL1-01.svg";


const Team = () => {
    useTitle("Team");
    const team = [
        { id: 1, name: "mishu", image: mishu, designation: "CEO", fcaebookicon: <FaFacebook />, linkedinicon: <FaLinkedin /> },
        { id: 2, name: "ismail", image: ismail, designation: "VP", fcaebookicon: <FaFacebook />, linkedinicon: <FaLinkedin /> },
        { id: 3, name: "iqbal", image: fahim, designation: "VP", fcaebookicon: <FaFacebook />, linkedinicon: <FaLinkedin /> },
        { id: 4, name: "Habibur Rahman", image: habib, designation: "VP", fcaebookicon: <FaFacebook />, linkedinicon: <FaLinkedin /> },
        { id: 5, name: "Fahim Shahriar", image: iqbal, designation: "VP", fcaebookicon: <FaFacebook />, linkedinicon: <FaLinkedin /> },
        // { id: 6, name: "Bulman", image: '', designation: "CEO", fcaebookicon: <FaFacebook/>, linkedinicon: <FaLinkedin/> },
    ]
    return (
        <section>
            <SocialContacts />
            <div className=" relative containerbg">
                <div className="containerbg relative rounded-b-[30px] border-b-4 border-teal-700 md:rounded-b-[100px] w-full h-[400px] flex flex-col items-center pb-5 justify-end m-auto">
                    <h2 className='fontCabinet text-center text-xl 3xl:text-4xl font-bold text-teal-400 uppercase z-30'>We are your software development team in the cloud.
                    </h2>
                    <p className=' text-sm sm:text-xl text-white text-center px-5 pt-3 z-20'>
                        Build products together with your dedicated team.
                        We do everything from research, planning, execution, deployment and quality assurance.
                    </p>
                    <img src={ChooseusImg} alt="" className=' absolute bottom-0 w-[400px] opacity-[0.5] z-10' />
                    <img className='w-[350px] hidden sm:block object-cover absolute bottom-0 right-0 opacity-[0.4]' src={teamImg} alt="" />
                    <img className='w-[350px] hidden sm:block object-cover absolute bottom-0 left-0 opacity-[0.4]' src={teamImg} alt="" />
                </div>

                <div className=" w-[400px] h-[400px] absolute top-[500px] -left-[140px] opacity-[0.2]">
                    <img className='w-full h-full object-cover' src={abstractCircle} alt="" />
                </div>

                <div className="w-full md:w-[768px] lg:w-[80%] 3xl:w-[1536px] flex flex-col justify-center m-auto">
                    {/* <div className=" w-full h-full">
                    <img className='w-full h-full rounded-md' src={teamHero} alt="teamHero" />
                  </div> */}

                    <TeamDevelopmentProcess />
                    <div className="pt-10 sm:pt-[80px]">
                        {/* <div className=" grid grid-cols-1 sm:grid-cols-3  3xl:grid-cols-4 gap-10">

                            {
                                team.map((team) => (

                                    <div key={team.id}>
                                        <div className="card__collection clear-fix">
                                            <div className="cards cards--two">
                                                <img src={team.image} className="img-responsive" alt="CardsImage" />
                                                <div className="bg-white"></div>
                                                <span className="cards--two__rect"></span>
                                                <span className="cards--two__tri"></span>
                                                <p>{team.name} <span className='text-sm font-normal'>({team.designation})</span></p>


                                                <ul className="cards__list">
                                                    <li className=''>
                                                        <Link className=" text-[16px]" to=""> {team.fcaebookicon}</Link>
                                                    </li>
                                                    <li>
                                                        <Link className=" text-[16px]" to=""> {team.linkedinicon}</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }


                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Team