import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Services from "../pages/Services";
import Home from "../pages/Home";
import Team from "../pages/Team";
import { Error } from "./Error";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Profile } from "../pages/Profile";
import Contactus from "../pages/ContactUs";
import ServiceDetails from "../pages/ServiceDetails";
import Careers from "../pages/Careers";
import CarerDetails from "../pages/CarerDetails";
import HowWeWork from "../pages/HowWeWork";
import BlogList from "../pages/BlogList";
import BlogDetails from "../pages/BlogDetails";
import BuildProduct from "../pages/BuildProduct";


const Index = () => {
  return (
    <>
      <BrowserRouter>
      <Navbar />
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="services/" element={<Services/>} />
        <Route path="details/:id" element={<ServiceDetails/>} />
        <Route path="team/" element={<Team />} />
        <Route path="careers/" element={<Careers/>} />
        <Route path="career/:id" element={<CarerDetails/>}/>
        <Route path="about/" element={<Profile />} />
        <Route path="contact/" element={<Contactus />} />
        <Route path="how-we-work/" element={<HowWeWork />} />
        <Route path="blog/" element={<BlogList />} />
        <Route path="blog/:id" element={<BlogDetails />} />
        <Route path="buld-product/" element={<BuildProduct/>} />


        

        <Route path="*" element={<Error />} />
        </Routes>
       <Footer/>
      </BrowserRouter>

    </>
  );
};

export default Index;
