import React, { useEffect, useRef } from 'react';
import analysis from '../assets/HowWeWork/analysis.svg';
import Planning from "../assets/HowWeWork/planning.svg";
import design from "../assets/HowWeWork/design.svg";
import development from "../assets/HowWeWork/development.svg";
import testing from "../assets/HowWeWork/testing.svg";
import feedback from "../assets/HowWeWork/feedback.svg";
import maintenance from "../assets/HowWeWork/maintenance.svg";
import DevelopmentProcess from '../components/DevelopmentProcess';
import SocialContacts from '../components/SocialContacts';
import { useTitle } from '../useTitle';

// JSON Data
const steps = [
    {
        id: 1,
        step: "Step 1",
        title: "Analysis",
        description: "Is the process of breaking a complex topic or substance into smaller parts in order to gain a better understanding of it. The main purpose of analysis is to find meaning so that the derived knowledge can be used to make informed decisions and make it real.",
        image: analysis,
        order: 'order-1',
    },
    {
        id: 2,
        step: "Step 2",
        title: "Planning",
        description: "Includes the plan, the thought process, action and implementation. Planning gives more power over the future. Planning is deciding in advance what to do, how to do it, when to do it, and who should do it. This bridges the gap from where the organization is to where it wants to be.",
        image: Planning,
        order: 'order-2',
    },
    {
        id: 3,
        step: "Step 3",
        title: "Design",
        description: "Project is an early phase of the project where a project’s key features, structure, criteria for success and major deliverable are all planned out. The point is to develop one or more designs which can be used to achieve the desired project goals.",
        image: design,
        order: 'order-1',
    },
    {
        id: 4,
        step: "Step 4",
        title: "Development",
        description: "Is the process and the facility of planning, organizing, coordinating and controlling the resources to accomplish specific goals. The process takes a transportation improvement from concept through construction.",
        image: development,
        order: 'order-2',
    },
    {
        id: 5,
        step: "Step 5",
        title: "Testing",
        description: "Phase means a group of activities designated for investigating and examining progress of a given project to provide stakeholders with information about actual levels of performance and quality of the project planning. The team makes a plan of key procedures and steps of testing.",
        image: testing,
        order: 'order-1',
    },
    {
        id: 6,
        step: "Step 6",
        title: "Feedback",
        description: "Is about listening actively, taking the time to analyze and then thinking of the best possible solution to perform better. It provides positive criticism and allows to see what everyone can change to improve their focus and results.",
        image: feedback,
        order: 'order-2',
    },
    {
        id: 7,
        step: "Step 7",
        title: "Maintenance",
        description: "Means a project that keeps a facility or asset in efficient operating condition, preserves the condition of the property, or restores property to a sound state after prolonged use.",
        image: maintenance,
        order: 'order-1',
    }
];

const HowWeWork = () => {
    const cardsRef = useRef([]);
    useTitle(`${'How We Work'}`);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('in-view');
                        observer.unobserve(entry.target); // Stop observing the target
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        const currentCardsRef = cardsRef.current;
        currentCardsRef.forEach((card) => {
            if (card) observer.observe(card);
        });

        return () => {
            currentCardsRef.forEach((card) => {
                if (card) observer.unobserve(card);
            });
        };
    }, []);

    return (
        <section className='containerbg'>
            <SocialContacts/>
            <div className="pt-[150px]">
                <div className="w-full md:w-[768px] lg:w-[80%] 3xl:w-[1536px] py-[40px] flex flex-col justify-center m-auto">
                    <div className="">
                        <DevelopmentProcess/>
                    </div>

                    <div className="mx-auto">
                        {steps.map((step, index) => (
                            <div
                                key={step.id}
                                ref={(el) => (cardsRef.current[index] = el)}
                                className={`cardNew ${index % 2 === 0 ? 'lg:pt-24 pt-24 lg:pb-48 pb-40' : 'pb-20 lg:pb-24'}`}
                            >
                                <div className={`flex flex-col lg:flex-row ${index % 2 === 0 ? '' : '-reverse'} justify-between items-center container-fluid lg:space-x-10`}>
                                    <div className={`flex ${step.order} mx-auto lg:justify-center lg:w-1/2 pb-6 z-[1] px-5 lg:pr-20`}>
                                        <img
                                            className="xl:w-[28.13vw] w-[78vw] h-[53.34vw] xl:h-[25vw] xl:max-w-[360px] xl:max-h-[320px] max-w-[300px] max-h-[200px]"
                                            src={step.image}
                                            alt={step.title}
                                        />
                                    </div>
                                    <div className={`flex ${index % 2 === 0 ? 'order-2' : 'order-1'} items-center md:w-1/2 z-[1]`}>
                                        <div className="px-5 lg:pl-20 lg:pr-20">
                                            <p className="text-[#FFFFFF] font-semibold text-lg md:text-2xl leading-7 text-left">
                                                <span className="h-[2px] w-10 inline-block bg-teal-400 mb-[5px] mr-4"></span>{step.step}
                                            </p>
                                            <h2 className="font-bold text-2xl md:text-5xl lg:leading-[65px] text-teal-400 text-primary py-4 text-left">{step.title}</h2>
                                            <p className="text-lg md:text-xl font-normal leading-7 text-gray-300 text-left">{step.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowWeWork;
