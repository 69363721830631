import React from 'react'
import abstractCircle from '../assets/bgElement/abstract circle-01.svg'
import IndustriesWeServe from './IndustriesWeServe';

import fullstack from "../assets/expertisArea/fullstack.svg"
import frontend from "../assets/expertisArea/frontend.svg"
import backend from "../assets/expertisArea/backend.svg"
import mobileApplication from "../assets/expertisArea/mobileApplication.svg"
import databaseDesign from "../assets/expertisArea/databaseDesign.svg"
import devops from "../assets/expertisArea/devops.svg"
import digitalMarketing from "../assets/expertisArea/digitalMarketing.svg"
import projectManagement from "../assets/expertisArea/projectManagement.svg"
import sqaTesting from "../assets/expertisArea/sqaTesting.svg"
import itConsultancy from "../assets/expertisArea/itConsultancy.svg"

const Contactus = () => {
    const expertiseAreas = [
        {
            src: fullstack,
            alt: "Full Stack Development",
            title: "Full Stack Development",
        },
        {
            src: frontend,
            alt: "Frontend Development",
            title: "Frontend Development",
        },
        {
            src: backend,
            alt: "Backend Development",
            title: "Backend Development",
        },
        {
            src: mobileApplication,
            alt: "Mobile Applications Development",
            title: "Mobile Applications Development",
        },
        {
            src: databaseDesign,
            alt: "Database Design & Development",
            title: "Database Design & Development",
        },
        {
            src: devops,
            alt: "DevOps",
            title: "DevOps",
        },
        {
            src: digitalMarketing,
            alt: "Digital Marketing",
            title: "Digital Marketing",
        },
        {
            src: projectManagement,
            alt: "Project Management",
            title: "Project Management",
        },
        {
            src: sqaTesting,
            alt: "SQA Testing & Automation",
            title: "SQA Testing & Automation",
        },
        {
            src: itConsultancy,
            alt: "IT Consultancy",
            title: "IT Consultancy",
        }
    ];

    
    return (
        <section className='containerbg pb-[80px]'>
            <div className='containerbg relative pb-[50px] rounded-b-[100px] 3xl:rounded-b-[500px] border-b-[30px] border-b-[#1f6e6c] shadow-2xl shadow-[#1f6e6c] w-full h-full rounded-t-[30px] 3xl:rounded-t-[200px] overflow-hidden'>
                <div className=" w-[400px] h-[400px] absolute top-[80px] -left-[140px] opacity-[0.2]">
                    <img className='w-full h-full object-cover' src={abstractCircle} alt="" />
                </div>
                <div className=" 3xl:pb-[50px] xl:mb-[0px] sm:px-3 w-full lg:w-[95%] xl:w-[80%] 3xl:[15px] flex-col items-stretch justify-center m-auto z-[4444]">
                   
                    <div id="technology-stacks" className="pt-[150px]">
                        <div className="px-0 md:px-4 lg:px-0">
                            <h2 className="text-center text-teal-400 md:text-5xl text-3xl font-semibold">Expertise Area</h2>
                            <p className="text-gray-300 md:text-lg text-base text-center pt-5 max-w-[625px] mx-auto">
                                We help small businesses around the world with amazing products that solve their business and web problems. Software chamber is special because
                            </p>
                            <div className="flex flex-wrap items-center gap-5 justify-center w-full mt-12 2dx:mt-17">
                                {expertiseAreas.map((area, index) => (
                                    <div key={index} className="p-4 md:p-8 lg:p-6 xl:p-8 pb-8 md:pb-4 md:w-auto 2dx:flex 2dx:justify-center">
                                        <div className="w-[140px] md:w-[200px] lg:w-[244px] 2dx:w-[320px] md:h-auto h-[150px] rounded-lg px-4 xl:px-8 flex flex-col items-center justify-center space-y-3">
                                            <img className="h-[50px] w-[50px]" src={area.src} alt={area.alt} />
                                            <div className="text-white text-center text-lg xl:text-xl font-bold h-[60px]">{area.title}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <IndustriesWeServe/>
                </div>
                <div className=" hidden 3xl:block w-[400px] h-[400px] absolute bottom-0 right-0 opacity-[0.2]">
                    <img className='w-full h-full object-cover rotate-180' src={abstractCircle} alt="" />
                </div>
            </div>
        </section>
    )
}

export default Contactus