import { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";

const Elementor = () => {
    const [startCount, setStartCount] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const currentRef = sectionRef.current; // Copy sectionRef.current to a variable

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setStartCount(true);
                    observer.disconnect();
                }
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0.1
            }
        );

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="w-full mt-[40px] pt-[50px] pb-[60px] sm:pb-[50px] 3xl:py-[100px] 3xl:px-[200px] ">
            {/* <div className="pb-[50px] sm:pb-[80px]">
                <h1 className='fontCabinet text-2xl sm:text-3xl 3xl:text-5xl font-bold text-teal-400'>Our Achievements</h1>
                <p className='text-gray-300 pt-3 text-sm sm:text-lg font-medium'></p>
            </div> */}

            <div className="grid grid-cols-2 gap-10 sm:grid-cols-2 3xl:grid-cols-4 items-center">
                <div className="w-full max-w-full px-3 text-center flex-0">
                    <div className="py-4 border-l-[1px]">
                        <h6 className="text-4xl font-bold pb-3 text-gray-300">
                            {startCount ? <CountUp start={0} end={3} duration={4} /> : 0}+
                        </h6>
                        <h4 className="font-bold text-sm sm:text-xl dark:text-gray-300">
                            <span>Years of Experience</span>
                        </h4>
                    </div>
                </div>

                <div className="w-full max-w-full px-3 text-center flex-0">
                    <div className="py-4 border-l-[1px]">
                        <h6 className="text-4xl font-bold pb-3 text-gray-300">
                            {startCount ? <CountUp start={0} end={16} duration={4} /> : 0}+
                        </h6>
                        <h4 className="font-bold text-sm sm:text-xl dark:text-gray-300">
                            <span>Tech Partners</span>
                        </h4>
                    </div>
                </div>


                <div className="w-full max-w-full px-3 text-center flex-0">
                    <div className="py-4 border-l-[1px]">
                        <h6 className="text-4xl font-bold pb-3 text-gray-300">
                            {startCount ? <CountUp start={0} end={4} duration={4} /> : 0}+
                        </h6>
                        <h4 className="font-bold text-sm sm:text-xl dark:text-gray-300">
                            <span>Ongoing Project</span>
                        </h4>
                    </div>
                </div>

                <div className="w-full max-w-full px-3 text-center flex-0">
                    <div className="py-4 border-l-[1px]">
                        <h6 className="text-4xl font-bold pb-3 text-gray-300">
                            {startCount ? <CountUp start={0} end={15} duration={4} /> : 0}+
                        </h6>
                        <h4 className="font-bold text-sm sm:text-xl dark:text-gray-300">
                            <span>Projects Completed</span>
                        </h4>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Elementor;
