import React from 'react'
import { Link } from 'react-router-dom'

const BlogLeft = ({latestPosts}) => {
  return (
    <div className=" md:flex-[40%] 3xl:flex-[30%] sidebar-right theiaStickySidebar flex flex-col gap-10">
    {/* <div className="card search-widget">
      <div className="card-body">
        <form className="search-form">
          <div className="input-group">
            <input type="text" placeholder="Search..." className="form-control" />
            <button type="submit" className="btn btn-primary"><i
              className="fa fa-search"></i></button>
          </div>
        </form>
      </div>
    </div> */}


    <div className="card post-widget border border-white rounded-[4px] overflow-hidden">
      <div className="card-header bg-teal-800 p-3 text-white font-bold border-b border-white">
        <h4 className="card-title">Latest Posts</h4>
      </div>
      {/* ----------------------Latest Posts---------------------- */}
      <div className="card-body">
        <ul className="latest-posts px-6 py-5">


          {latestPosts.map((post, index) => (
            <li>
              <div className="post-thumb">
                <Link to="">
                  <img className="img-fluid" src={post.image} alt="" />
                </Link>
              </div>
              <div className="post-info">
                <h4>
                  <Link to=''>{post.title}</Link>
                </h4>
                <p>{post.date}</p>
              </div>
            </li>
          ))}





        </ul>
      </div>

    </div>


    <div className="card category-widget border border-white rounded-[4px] overflow-hidden">
      <div className="card-header bg-teal-800 p-3 border-b">
        <h4 className=" text-white font-bold">Blog Categories</h4>
      </div>
      <div className="card-body px-6 py-5">
        <ul className="categories">
          <li><Link to=''>Cardiology <span>(62)</span></Link></li>
          <li><Link to=''>Health Care <span>(27)</span></Link></li>
          <li><Link to=''>Nutritions <span>(41)</span></Link></li>
          <li><Link to=''>Health Tips <span>(16)</span></Link></li>
          <li><Link to=''>Medical Research <span>(55)</span></Link></li>
          <li><Link to=''>Health Treatment <span>(07)</span></Link></li>
        </ul>
      </div>
    </div>


    <div className="card tags-widget border border-white rounded-[4px] overflow-hidden">
      <div className="card-header bg-teal-800 p-3 border-b">
        <h4 className="card-title text-white font-bold">Tags</h4>
      </div>
      <div className="card-body px-6 py-5">
        <ul className="tags">
          <li><Link to='' className="tag">Children</Link></li>
          <li><Link to='' className="tag">Disease</Link></li>
          <li><Link to='' className="tag">Appointment</Link></li>
          <li><Link to='' className="tag">Booking</Link></li>
          <li><Link to='' className="tag">Kids</Link></li>
          <li><Link to='' className="tag">Health</Link></li>
          <li><Link to='' className="tag">Family</Link></li>
          <li><Link to='' className="tag">Tips</Link></li>
          <li><Link to='' className="tag">Shedule</Link></li>
          <li><Link to='' className="tag">Treatment</Link></li>
          <li><Link to='' className="tag">Dr</Link></li>
          <li><Link to='' className="tag">Clinic</Link></li>
          <li><Link to='' className="tag">Online</Link></li>
          <li><Link to='' className="tag">Health Care</Link></li>
          <li><Link to='' className="tag">Consulting</Link></li>
          <li><Link to='' className="tag">Doctors</Link></li>
          <li><Link to='' className="tag">Neurology</Link></li>
          <li><Link to='' className="tag">Dentists</Link></li>
          <li><Link to='' className="tag">Specialist</Link></li>
          <li><Link to='' className="tag">Doccure</Link></li>
        </ul>
      </div>
    </div>

  </div>
  )
}

export default BlogLeft