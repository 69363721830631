import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from '../assets/logo3.png'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }

      if (currentScrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleLinkClick = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <nav
        className={` z-[9999] fixed top-0 left-0 right-0 transition-transform duration-300 ${showNavbar ? "transform translate-y-0" : "transform -translate-y-full"
          } ${isScrolled ? "containerbg" : "bg-transparent"} border-b-[5px] border-[#1f6e6c] shadow-xl shadow-teal-800 rounded-b-[20px]`}
      >
        <div className="relative">
          <div className="px-3 w-full lg:max-w-[95%] 3xl:w-[1536px] mx-auto">
            <div className="flex items-center justify-between h-[5rem]">
              <div className="w-full flex items-center justify-between">
                <Link to='/' className="flex-shrink-0">
                  <img
                    className="w-[200px] sm:w-[300px] h-[80px] object-cover"
                    src={logo}
                    width={200}
                    height={40}
                    alt="Workflow"
                  />
                </Link>

                <div className="hidden lg:block z-20">
                  <div className="ml-10 flex items-baseline space-x-[60px] text-gray-200">
                    <Link
                      to="/"
                      className="relative group py-4 text-[16px] 3xl:text-xl font-medium"
                    >
                      <span className="absolute bottom-0 left-0 w-0 h-[3px] bg-teal-400 group-hover:w-full group-hover:transition-all"></span>
                      Home
                    </Link>

                    <Link
                      to="/services"
                      className="relative group py-4 text-[16px] 3xl:text-xl font-medium"
                    >
                      <span className="absolute bottom-0 left-0 w-0 h-[3px] bg-teal-400 group-hover:w-full group-hover:transition-all"></span>
                      Services
                    </Link>

                    <Link
                      to='/team'
                      className="relative group py-4 text-[16px] 3xl:text-xl font-medium"
                    >
                      <span className="absolute bottom-0 left-0 w-0 h-[3px] bg-teal-400 group-hover:w-full group-hover:transition-all"></span>
                      Team
                    </Link>
                    <Link
                      to='/careers'
                      className="relative group py-4 text-[16px] 3xl:text-xl font-medium"
                    >
                      <span className="absolute bottom-0 left-0 w-0 h-[3px] bg-teal-400 group-hover:w-full group-hover:transition-all"></span>
                      Careers
                    </Link>
                    <Link
                      to='/about'
                      className="relative group py-4 text-[16px] 3xl:text-xl font-medium"
                    >
                      <span className="absolute bottom-0 left-0 w-0 h-[3px] bg-teal-400 group-hover:w-full group-hover:transition-all"></span>
                      About Us
                    </Link>
                    <Link
                      to='/contact'
                      className="relative group py-4 text-[16px] 3xl:text-xl font-medium"
                    >
                      <span className="absolute bottom-0 left-0 w-0 h-[3px] bg-teal-400 group-hover:w-full group-hover:transition-all"></span>
                      Contact Us
                    </Link>

                    <Link
                      to='/Blog'
                      className="relative group py-4 text-[16px] 3xl:text-xl font-medium"
                    >
                      <span className="absolute bottom-0 left-0 w-0 h-[3px] bg-teal-400 group-hover:w-full group-hover:transition-all"></span>
                      Blog
                    </Link>
                  </div>
                </div>
              </div>

              <div className=" flex lg:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className=" border-[1px] border-[#38bdb8] inline-flex items-center justify-center p-2 rounded-md text-[#38bdb8] hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>

          {isOpen && (
            <div className="containerbg  lg:hidden border-t-2 border-teal-400" id="mobile-menu">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <Link
                  onClick={handleLinkClick}
                  to="/"
                  className="hover:bg-teal-800 text-gray-300 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Home
                </Link>
                <Link
                  onClick={handleLinkClick}
                  to="/services"
                  className="hover:bg-teal-800 text-gray-300 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Services
                </Link>
                <Link
                  onClick={handleLinkClick}
                  to="team/"
                  className="text-gray-300 hover:bg-teal-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Team
                </Link>
                <Link
                  onClick={handleLinkClick}
                  to="/careers"
                  className="text-gray-300 hover:bg-teal-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Careers
                </Link>

                <Link
                  onClick={handleLinkClick}
                  to="about/"
                  className="text-gray-300 hover:bg-teal-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  About Us
                </Link>
                <Link
                  onClick={handleLinkClick}
                  to="contact/"
                  className="text-gray-300 hover:bg-teal-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Contact Us
                </Link>

                <Link
                  onClick={handleLinkClick}
                  to="blog/"
                  className="text-gray-300 hover:bg-teal-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                 Blog
                </Link>

              </div>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
