import React, { useEffect, useState } from 'react';
import infineImg from '../assets/infine img2.png.png';

import research from "../assets/Development Process/Research.png"
import design from "../assets/Development Process/Design.png"
import development from "../assets/Development Process/Development.png"
import testing from "../assets/Development Process/Testing.png"
import deployment from "../assets/Development Process/Deployment.png"
import Maintenance from "../assets/Development Process/Maintenance.png"


const DevelopmentProcess = () => {
    const [activeId, setActiveId] = useState(1);
    // eslint-disable-next-line
    const [bgColor, setBgColor] = useState("#106617");

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveId(prevId => (prevId % 6) + 1);
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    const DevelopmentProcessData = [
        {
            id: 1,
            title: "Research",
            description: "Consultation and Requirements Gathering. Collaborate with stakeholders to understand their vision, gather project scope, target audience, and functional needs, ensuring alignment with business objectives.",
            image: research,
            bordercolor: "#29bdcc",
            color: "#106617"
        },
        {
            id: 2,
            title: "Design",
            description: "Product Architecture, Design, & Prototype. Create detailed architecture and prototypes for user interface visualization, enabling early feedback and iterative improvements.",
            image: design,
            bordercolor: "#1c3b47",
            color: "#106617"
        },
        {
            id: 3,
            title: "Development",
            description: "Software Coding & Optimization. Develop code, ensuring efficiency and scalability through best practices, continuous integration, and code reviews.",
            image: development,
            bordercolor: "#55381f",
            color: "#106617"
        },
        {
            id: 4,
            title: "Testing",
            description: "Quality Assurance & Troubleshooting. Conduct unit, integration, and system tests to identify and fix defects, ensuring high-quality, reliable software.",
            image: testing,
            bordercolor: "#882739",
            color: "#106617"
        },
        {
            id: 5,
            title: "Deployment",
            description: "Launch, Beta Live, & Live. Deploy software in stages: beta testing for feedback, followed by the final live launch to ensure a smooth transition to production.",
            image: deployment,
            bordercolor: "#f0b224",
            color: "#106617"
        },
        {
            id: 6,
            title: "Maintenance",
            description: "Monitoring, Analysis, & Complete Support. Provide ongoing maintenance, continuous monitoring, performance analysis, and support to ensure the software remains up-to-date and secure.",
            image: Maintenance,
            bordercolor: "#3d1b9c",
            color: "#106617"
        },

        {
            id: 7,
            title: "Monitor",
            description: "Monitoring, Analysis, & Complete Support. Provide ongoing maintenance, continuous monitoring, performance analysis, and support to ensure the software remains up-to-date and secure.",
            image: Maintenance,
            bordercolor: "#3d1b9c",
            color: "#106617"
        },
    ];

    const toggleSection = (id) => {
        setActiveId(activeId === id ? null : id);
    };

    const activeItem = DevelopmentProcessData.find(item => item.id === activeId);

    return (
        <section className='pt-[50px] z-[8888] text-[#106617]'>
            <div className="relative w-full">
                <div className="relative px-3 lg:px-0 py-10 w-full flex lg:w-[80%] xl:w-full flex-col items-stretch justify-center m-auto z-100">
                    <div className="flex flex-col xl:flex-row w-full gap-10 3xl:gap-[100px]">

                        <div className="w-full xl:flex-[40%] xl:order-1 ">
                            <div className="pb-[50px] sm:pb-[80px]">
                                <h1 className='fontCabinet text-2xl sm:text-4xl 3xl:text-5xl font-bold text-teal-400'>Our Software Development Process:</h1>
                                <p className='text-gray-300 pt-3 text-sm sm:text-lg font-medium'>We follow a well-structured and defined process with the end goal of creating a product that meets your business objectives</p>
                            </div>

                            <div className=" w-[300px] h-fit sm:w-[600px] sm:h-fit md:w-full md:h-fit lg:w-full lg:h-fit relative pb-10 xl:pb-0">
                                <img className='infineUm-image-animation  3xl:mt-0 w-full object-cover opacity-[0.8] 3xl:w-[800px] h-fit' src={infineImg} alt="infine" />

                                <img onClick={() => toggleSection(1)} className='animat-bg absolute w-[25px] h-[25px] sm:w-[50px] sm:h-[50px] object-cover top-[3%]  left-[14%]  sm:top-[3%]  sm:left-[14%]  lg:top-[3%]    lg:left-[14%]      rounded-full p-1 sm:p-3 shadow-lg shadow-gray-500 cursor-pointer hover:rotate-[360dg]' src={research} alt="research icon" style={{ backgroundColor: activeId === 1 ? bgColor : '#00897D' }} />
                                <img onClick={() => toggleSection(2)} className='animat-bg absolute w-[25px] h-[25px] sm:w-[50px] sm:h-[50px] object-cover top-[62%] right-[26%] sm:top-[69%] sm:right-[26%] lg:top-[78%]   lg:right-[26%]      rounded-full p-1 sm:p-3 shadow-lg shadow-gray-500 cursor-pointer' src={design} alt="design icon" style={{ backgroundColor: activeId === 2 ? bgColor : '#38A0E0' }} />
                                <img onClick={() => toggleSection(3)} className='animat-bg absolute w-[25px] h-[25px] sm:w-[50px] sm:h-[50px] object-cover top-[28%] right-[0%]  sm:top-[31%] sm:right-[0%]  lg:top-[35%]   lg:right-[0%]      rounded-full p-1 sm:p-3 shadow-lg shadow-gray-500 cursor-pointer' src={development} alt="development icon" style={{ backgroundColor: activeId === 3 ? bgColor : '#36D4C3' }} />
                                <img onClick={() => toggleSection(4)} className='animat-bg absolute w-[25px] h-[25px] sm:w-[50px] sm:h-[50px] object-cover top-[0%]  right-[22%] sm:top-[0%]  sm:right-[22%] lg:right-[22%]      rounded-full p-1 sm:p-3 shadow-lg shadow-gray-500 cursor-pointer' src={testing} alt="testing icon" style={{ backgroundColor: activeId === 4 ? bgColor : '#102164' }} />
                                <img onClick={() => toggleSection(5)} className='animat-bg absolute w-[25px] h-[25px] sm:w-[50px] sm:h-[50px] object-cover top-[28%] left-[0%]   sm:top-[30%] sm:left-[0%]   lg:top-[35%]   lg:left-[0%]      rounded-full p-1 sm:p-3 shadow-lg shadow-gray-500 cursor-pointer' src={deployment} alt="deployment icon" style={{ backgroundColor: activeId === 5 ? bgColor : '#2DA79C' }} />
                                <img onClick={() => toggleSection(6)} className='animat-bg absolute w-[25px] h-[25px] sm:w-[50px] sm:h-[50px] object-cover top-[64%] left-[16%]  sm:top-[70%] sm:left-[16%]  lg:top-[80%]   lg:left-[16%]      rounded-full p-1 sm:p-3 shadow-lg shadow-gray-500 cursor-pointer' src={Maintenance} alt="Maintenance icon" style={{ backgroundColor: activeId === 6 ? bgColor : '#470A89' }} />

                                <img onClick={() => toggleSection(7)} className='animat-bg absolute w-[25px] h-[25px] sm:w-[50px] sm:h-[50px] object-cover left-[44%] top-[36%] sm:left-[44%] sm:top-[45%]    rounded-full p-1 sm:p-3 shadow-lg shadow-gray-500 cursor-pointer' src={Maintenance} alt="Maintenance icon" style={{ backgroundColor: activeId === 7 ? bgColor : '#470A89' }} />

                            </div>
                        </div>


                        <div className="w-full xl:flex-[50%] xl:order-2 pt-[0px]">
                          
                                        <div className="xl:mt-[250px] flex items-center justify-center m-auto">
                                            <div className="rotate-6 h-[400px] w-[300px] border-[0.5px] border-white shadow-lg shadow-gray-800 rounded-2xl overflow-hidden">
                                                <div className="w-full h-[50px] bg-[#1d3645] p-3">
                                                    <div className=" flex items-center gap-4">
                                                        <p className='bg-teal-400 p-2 rounded-full border border-white'
                                                        style={{ backgroundColor: activeId ? bgColor : '#36D4C3' }}
                                                        >
                                                            <img className='w-[15px] h-[15px]' src={activeItem && activeItem.image} alt={activeItem && activeItem.title} />
                                                        </p>
                                                        <span className='text-xl font-medium text-teal-400'>{activeItem && activeItem.title}</span>
                                                    </div>
                                                </div>
                                                <div className="cardbg w-full h-[300px] flex flex-col items-center justify-center m-auto">
                                                    <p className=' px-1 text-sm text-center text-white'>
                                                    {activeItem && activeItem.description}
                                                    </p>
                                                </div>
                                                <div className="w-full h-[50px] bg-[#1d3645] p-3 flex items-center justify-center m-auto">
                                                    <div className="w-[80px] h-[4px] rounded-xl bg-gray-500"></div>
                                                </div>
                                            </div>
                                        </div>
                             
                        </div>
                    </div>
                    
                </div>

            </div>
        </section>
    );
};

export default DevelopmentProcess;
