import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image1 from '../assets/Testimonials/low.jfif'
import ural from '../assets/Testimonials/ural.jfif'
import Sakib from '../assets/Testimonials/Sakib.png'
import image4 from '../assets/Testimonials/4.webp'
import image5 from '../assets/Testimonials/5.webp'
import image6 from '../assets/Testimonials/6.webp'
import image7 from '../assets/Testimonials/7.webp'
import image8 from '../assets/Testimonials/8.webp'
import { Link } from 'react-router-dom';


const Testimonial = () => {
    const testimonial = [
        {
            "id": 1,
            "name": "Yadnan Rafique Rossy",
            "description": "I am thinking of making IT department for my low firm. ask for the advice of SoftwareChamber, they tell me about the risk & cost. They said you can take us as your ready department / dev team.Since then they have been efficiently and orderly engaged as my IT department. Hopefully this amazing path of ours will remain intact forever.",
            "designation": "Advocate of the Bangladesh Supreme Court",
            "Image": image1,
            "projectTitle": "Chairman  KRLF",
            "projectLink": ""
        },

        {
            "id": 2,
            "name": "Md: Shuvo",
            "description": "For about 3-4 years I hired more than 20 freelancers, but I did not get my desired results.I started working with SaftwareChamber because of the low budget offer.I did not expect the experience of working with Softwarehchamber to be so wonderful.It feels like a team of my own making.",
            "designation": "Chairman",
            "Image": ural,
            "projectTitle": "Ural Holidays",
            "projectLink": ""
        },

        {
            "id": 3,
            "name": "Mustafa Nazmus Sakib",
            "description": "Software chamber proved to be an invaluable partner in developing our software platform. Their attention to detail and proactive approach ensured we achieved our goals efficiently. I wouldn't hesitate to work with them again.",
            "designation": "CEO",
            "Image": Sakib,
            "projectTitle": "os digital world",
            "projectLink": "https://osdigitalworld.com"
        },
        {
            "id": 4,
            "name": "Sarah Davis",
            "description": "The team at Software chamber is simply outstanding. Their technical knowledge, coupled with their commitment to customer satisfaction, makes them a pleasure to work with. We saw significant improvements in our software performance.",
            "designation": "Project Manager, LMN Enterprises",
            "Image": image4,
            "projectTitle": "Flight Booking",
            "projectLink": ""
        },
        {
            "id": 5,
            "name": "Michael Wilson",
            "description": "Choosing Software chamber was one of the best decisions for our business. Their innovative solutions and dedication to our project ensured we achieved our objectives ahead of schedule. They are a top-notch software development company.",
            "designation": "COO, DEF Ltd.",
            "Image": image5,
            "projectTitle": "Flight Booking",
            "projectLink": ""
        },
        {
            "id": 6,
            "name": "Jessica Martinez",
            "description": "I was thoroughly impressed with Software chamber's ability to understand our needs and deliver a product that exceeded our expectations. Their attention to detail and excellent customer service set them apart from the competition.",
            "designation": "Director of IT, GHI Tech",
            "Image": image6,
            "projectTitle": "Flight Booking",
            "projectLink": ""
        },
        {
            "id": 7,
            "name": "Chris Thompson",
            "description": "Software chamber delivered exceptional service and high-quality software solutions. Their team's expertise and commitment to our project's success made all the difference. We look forward to future collaborations.",
            "designation": "Head of Development, JKL Inc.",
            "Image": image7,
            "projectTitle": "Flight Booking",
            "projectLink": ""
        },
        {
            "id": 8,
            "name": "Laura Garcia",
            "description": "The professional and dedicated team at Software chamber provided us with outstanding service. They consistently went above and beyond to ensure our project's success. Their innovative approach is truly commendable.",
            "designation": "VP of Technology, MNO Solutions",
            "Image": image8,
            "projectTitle": "Flight Booking",
            "projectLink": ""
        }







    ]
    const carouselProperties = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        // autoplaySpeed: 0,
        // cssEase: 'linear',
        // variableWidth: true,
        pauseOnHover: true,
        arrows: false,

        responsive: [
            {
                breakpoint: 426,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    centerMode: false,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    centerMode: false,
                    slidesToScroll: 2,
                },

            },
        ],
    };
    return (
        <section className=''>
            <div className=' relative'>
                <div className="flex-col items-stretch justify-center m-auto z-[4444]">
                    <div className=" flex items-center justify-center">
                        <div className=" w-[600px] sm:w-[1200px] 3xl:w-[1600px] p-5 3xl:p-0 h-fit mx-auto pt-6 mt-[60px] rounded-t-lg">
                            <div className=" relative ">
                                <h1 className='fontCabinet pb-2 text-2xl text-center sm:text-3xl 3xl:text-5xl font-bold text-teal-400 capitalize '>Testimonials</h1>
                                <p className=' text-sm sm:text-xl text-gray-300 text-center'>Hear what our clients have to say</p>
                            </div>

                            <div className=" w-full xl:min-w-screen flex items-center justify-center py-5">
                                <div className="w-full px-5 pt-16 text-gray-300">
                                    <div className="w-full mx-auto">

                                        <div className="swiper-slide">
                                            <Slider {...carouselProperties}>
                                                {
                                                    testimonial?.map((testimonial) => (
                                                        <div key={testimonial.id} className="px-10 sm:px-3 relative w-full md:w-1/3 pt-[85px] 3xl:pt-[100px]">
                                                            <div className="ChooseUsCard mx-10 min-h-[250px] sm:w-full sm:mx-auto rounded-lg border border-gray-500 shadow-lg shadow-gray-800 p-5 font-light mb-6">
                                                                <div className="w-full flex mb-4 items-center">

                                                                    <div className="overflow-hidden absolute -left-[6%] -top-[70px] sm:left-[40%] xl:left-[45%] 3xl:-top-[85px] 3xl:left-[44%]   transform: translate(-50%, -50%) rounded-full w-[130px] h-[130px] sm:w-[110px] sm:h-[110px] 3xl:w-[150px] 3xl:h-[150px] bg-gray-50 border-4 border-[#1f6e6c]">
                                                                        <img className=' w-full h-full object-cover' src={testimonial.Image} alt={testimonial.name} />
                                                                    </div>

                                                                    <div className=" order-2 sm:order-1 flex-grow pl-3 flex flex-col gap-0">
                                                                        <h6 className="font-bold text-xl xl:text-2xl capitalize">{testimonial.name}</h6>
                                                                        <span className='w-full text-[16px] font-bold '>{testimonial.designation}</span>
                                                                        <p className='text-sm font-bold sm:hidden'>Project: <span className=' font-medium'>{testimonial.projectTitle}</span></p>
                                                                        <span className='text-sm'>⭐⭐⭐⭐⭐</span>
                                                                    </div>


                                                                    <div className=" order-1 sm:order-2 opacity-0 sm:opacity-100">
                                                                        <div className="text-start flex flex-col gap-2">
                                                                            <p className='text-sm font-bold capitalize'>ORG: <span className=' font-medium'>{testimonial.projectTitle}</span></p>
                                                                            <p className='text-sm font-bold capitalize'>Products: <Link className=' font-medium text-blue-500' to={testimonial.projectLink}> Click me..</Link></p>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div className=" relative w-full text-center pt-5">
                                                                    <p className="text-sm xl:text-[18px] leading-tight"><span className=" absolute -top-10 left-[50%] text-[100px] opacity-[0.4] leading-none italic font-bold mr-1 text-gray-400">"</span>{testimonial.description}
                                                                        {/* <span className="text-2xl leading-none italic font-bold text-gray-400 ml-1">"</span> */}
                                                                    </p>
                                                                    {/* 
                                                                       <div className="text-start flex flex-col gap-2">
                                                                       <span>Project Title</span>
                                                                       <span>Project Linik</span>
                                                                       </div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </Slider>



                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonial