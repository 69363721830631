import React, { useEffect, useRef } from 'react';
import projectManager from "../assets/team/teamDevelopments/project-manager.png"
import frontend from "../assets/team/teamDevelopments/front-end.png"
import Backend from "../assets/team/teamDevelopments/backend.png"
import FullStack from "../assets/team/teamDevelopments/web-development.png"
import DevOps from "../assets/team/teamDevelopments/devops.png"
import QA from "../assets/team/teamDevelopments/qa.png"
import ui from "../assets/team/teamDevelopments/ux-design.png"
import ProjectArchitect from "../assets/team/teamDevelopments/project.png"
import BusinessAnalyst from "../assets/team/teamDevelopments/presentation.png"
import SEO from "../assets/team/teamDevelopments/seo (1).png"

// JSON Data
const TeamDevelopments = [
    {
        id: 1,
        step: "1",
        title: "Project Manager",
        employeeDoes: [
            " What a Project Manager does:",
            " Organizes the work of the web team itself;",
            "Negotiates with clients;",
            " Facilitates communication between team members, stakeholders and clients;",
            "Makes decisions affecting the progress of work;",
            "Identifies and removes potential obstacles;",
            " Allocates resources effectively, including budgets;",
            "Plans and defines the scope of tasks;",
            "Ensures deliverables meet project requirements and standards through regular reviews."
        ],

        employeesHouldKnow: [
            " What a Project Manager should know:",

            "Leadership and team management skills;",
            "Different methodologies and how to apply them depending on project needs;",
            "Communication and problem solving skills;",
            " Basics of budgeting and financial management.",
        ],

        image: projectManager
    },
    {
        id: 2,
        step: "2",
        title: "Frontend Developers",
        employeeDoes: [
            " What Front - end developers do:",
            "Develop user functionality;",
            "Create libraries and reusable code;",
            "Provide a technical justification for the user interface design;",
            "Accelerate and scale the application through optimization.",
        ],
        image:frontend
    },
    {
        id: 3,
        step: "3",
        title: "Backend Developers",
        employeeDoes: [
            " What Backend developers do:",
            "Integrate custom elements developed by Front-end developers with server-side logic;",
            "Ensure high speed and scalability of the application;",
            "Secure and protect data;",
            "Implement a data storage solution."
        ],
        image: Backend
    },

    {
        id: 4,
        step: "4",
        title: "Full-stack Developers",
        employeeDoes: [
            "What Full-stack developers do:",

            "Manage front-end and back-end tasks, ensuring optimal user experience and functionality.",
            " Oversee database operations, including storage, retrieval, and manipulation, using various technologies for efficient data management.",
            "Ensure seamless integration between different system components, essential for the smooth operation of web applications.",
        ],
        image: FullStack
    },

    {
        id: 5,
        step: "5",
        title: "DevOps Engineers",
        employeeDoes: [
            " What DevOps engineers do:",

            "Create, customize, and launch new development tools and infrastructure;",
            'Automate and improve deployment pipelines;',
            "Manage infrastructure as code;",
            'Monitor system performance and health;',
            "Secure and protect systems from hacker threats;",
            "Find technical problems and create software updates and 'fixes';",
            'Set up Continuous Integration/Continuous Deployment pipelines.',
        ],
        image: DevOps
    },

    {
        id: 6,
        step: "6",
        title: "QA Engineers",
        employeeDoes: [
            " What QA engineers do:",

            "Conduct functional, regression, performance, and usability testing;",
            'Identify and report bugs;',
            "Develop and maintain automated test scripts;",
            'Collaborate closely with developers and stakeholders;',
        ],
        image: QA
    },

    {
        id: 7,
        step: "7",
        title: "UI/UX Designer",
        employeeDoes: [
            "What UI/UX designers do:",

            "Determine the target audience;",
            'Create wireframes and prototypes;',
            "Develop eye-catching interfaces;",
            "Conduct usability testing;",
            "Verify project relevance and demand;",
            "Anticipate potential user challenges in software interaction.",
        ],
        image: ui
    },

    {
        id: 8,
        step: "8",
        title: "Project Architect",
        employeeDoes: [
            "What Project Architects do:",

            'Define the technical vision and overall website architecture;',
            "Ensure architectural integrity by establishing design principles, patterns, and guidelines;",
            'Provide guidance and leadership to the development team, assisting with architectural decisions, resolving technical challenges, and ensuring adherence to best practices;',
            'Collaborate closely with stakeholders to understand requirements, provide technical insights, and ensure alignment between technical solutions and business objectives.',
        ],
        image: ProjectArchitect
    },

    {
        id: 9,
        step: "9",
        title: "Business Analyst",
        employeeDoes: [
            "What Business Analysts do:",

            "Collect and analyze business requirements through meetings with stakeholders, interviews, and document analysis;",
            "Translate business requirements into technical specifications and user stories;",
            "Act as liaisons between business stakeholders and dedicated web development team, facilitating effective communication;",
            "Ensure that the developed custom web solutions align with business goals and objectives.",
        ],
        image: BusinessAnalyst
    },

    {
        id: 10,
        step: "10",
        title: "SEO Expert",
        employeeDoes: [
            " What SEO Experts do:",

            "Conduct comprehensive keyword research;",
            "Analyze keyword competitiveness and search volume;",
            "Optimize on-page elements such as meta tags, headings, URLs, and content structure;",
            "Engage in off-page optimization activities such as link building, social media marketing, and influencer outreach;",
            "Conduct technical SEO audits to identify and fix issues that may affect the website's performance;",
            "Make sure that your website is ranked in the first positions in SERP for relevant keywords.",
        ],
        image: SEO
    },


];

const TeamDevelopmentProcess = () => {
    const cardsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('in-view');
                        observer.unobserve(entry.target); // Stop observing the target
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        const currentCardsRef = cardsRef.current;
        currentCardsRef.forEach((card) => {
            if (card) observer.observe(card);
        });

        return () => {
            currentCardsRef.forEach((card) => {
                if (card) observer.unobserve(card);
            });
        };
    }, []);

    return (
        <section>
            <div className="mx-auto">
                {TeamDevelopments.map((TeamDevelopment, index) => (
                    <div
                        key={TeamDevelopment.id}
                        ref={(el) => (cardsRef.current[index] = el)}
                        className={`cardNew ${index % 2 === 0 ? 'lg:pt-24 pt-24 lg:pb-48 pb-40' : 'pb-20 lg:pb-24'}`}
                    >
                        <div className={`flex flex-col lg:flex-row justify-between items-center container-fluid ${index % 2 !== 0 ? '-reverse relative' : ''} lg:space-x-10`}>
                            <div className={`flex ${index % 2 === 0 ? 'order-1' : 'order-2'} mx-auto lg:justify-center lg:w-1/2 pb-6 z-[1] px-5 lg:pr-20`}>
                                <img src={TeamDevelopment.image} alt={TeamDevelopment.title} className="TeamDevelopment-image-class" />
                            </div>
                            <div className={`flex ${index % 2 === 0 ? 'order-2' : 'order-1'} items-center md:w-1/2 z-[1]`}>
                                <div className="px-5 lg:pl-20 lg:pr-20">
                                    <p className="text-[#FFFFFF] font-semibold text-lg md:text-2xl leading-7 text-left">
                                        <span className="h-[2px] w-10 inline-block bg-teal-400 mb-[5px] mr-4"></span>{TeamDevelopment.step}
                                    </p>
                                    <h2 className="font-bold text-2xl md:text-5xl lg:leading-[65px] text-teal-400 text-primary py-4 text-left">{TeamDevelopment.title}</h2>

                                    <div className="text-lg md:text-xl font-normal leading-7 text-gray-300 text-left">
                                        {Array.isArray(TeamDevelopment.employeeDoes) && TeamDevelopment.employeeDoes.length > 0 && (
                                            <>
                                                <p className="font-bold">{TeamDevelopment.employeeDoes[0]}</p>
                                                <ul className="list-disc pl-0 mt-2">
                                                    {TeamDevelopment.employeeDoes.slice(1).map((desc, i) => (
                                                        <li key={i} className="mt-1">
                                                            {desc}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        )}
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default TeamDevelopmentProcess;
